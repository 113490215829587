export class TipoMenu {

    id?: number;
    descrizione?: string;
    idDieta?: number;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }

    get value(): TipoMenu {
        return this;
    }

}


