export class stanza {
    idStanza: number;
    desStanza: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
    get viewValue(): string{
        return this.desStanza ? this.desStanza : '';
    }

    get value(): stanza{
        return this;
    }
}

export class unitaServizio {
    idUnitaServizio: number;
    descUnitaServizio: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.descUnitaServizio ? this.descUnitaServizio : '';
    }

    get value(): unitaServizio{
        return this;
    }
}

