import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestiService} from '../../../services/config/testi.service';

@Component({
  selector: 'app-form-field-footer',
  templateUrl: './form-field-footer.component.html',
  styleUrls: ['./form-field-footer.component.scss']
})
export class FormFieldFooterComponent implements OnInit {

  @Input() disabled?: boolean;
  @Input() showDelete?: boolean;
  @Input() showCancel? = true;
  @Input() showSave? = true;

  @Input() saveStyle?: string;
  @Input() cancelStyle?: string;
  @Input() cancelAsReset?: boolean;

  @Input() blockDelete?: boolean = false;
  @Input() blockCancel?: boolean = false;
  @Input() blockSave?: boolean = false;

  @Input() textCancel?: string;
  @Input() textDelete?: string;
  @Input() textSave?: string;
  @Input() color?: string;

  @Input() confPopup?: boolean = false;

  @Output() saveForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() reset = new EventEmitter();

  testi;

  constructor(private testiService: TestiService) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit() {
  }

}
