import {Dieta} from "./Dieta";

export class Pazienti {

    id?: number;
    codice?: string;
    nome?: string;
    cognome?: string;
    nominativo?: string;
    cf?: string;
    presente?: number;
    letto?: number;
    dieta?: Dieta;

    constructor(data: any) {
        Object.assign(this, data);
        this.dieta = data.dieta ? new Dieta(data.dieta) : undefined;
    }

    get viewValue(): string {
        return this.nominativo ? this.nominativo : this.codice + " - " + this.cognome + " " + this.nome;
    }
    get value(): Pazienti {
        return this;
    }
}


