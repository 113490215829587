<div #dropdownId class="dropdown-container" *ngIf="!multiple && !empty">
     <!--OPT per tooltip this.msgService.msgSuccess(this.testi.richiesta);-->
      <mat-select *ngIf="tooltip"
                style="width:{{dropdownSize+'px'}}"
                [value]="valore"
                (valueChange)="valueChange($event)"
                [disabled]="disabled"
                [compareWith]="compareOgg"
                [ngClass]="{ 'input-error': invalid }"
                placeholder="{{testo}}">

          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

          <input *ngIf="ricerca"
                 matInput
                 class="inputSearch"
                 type="text"
                 [ngClass]="{ 'loading': loading }"
                 [(ngModel)]="valoreInput"
                 [placeholder]="'Cerca'"
                 (keyup)="inputFilter()">

          <mat-option *ngIf="clean" [value]="null"
                      class="clear"
                      [ngClass]="{ 'loading': loading }">
            <i class="fas fa-times"></i>
          </mat-option>

          <mat-option matTooltip="{{getToolTip(select)}}"
                      *ngFor="let select of _lista | filterInput:valoreInput"
                      [ngClass]="{ 'loading': loading }"
                      [value]="label ? select : select.value">
            {{label ? select[label] : select.viewValue}}
          </mat-option>
      </mat-select>
    <mat-select *ngIf="!tooltip"
                style="width:{{dropdownSize+'px'}}"
                [value]="valore"
                (valueChange)="valueChange($event)"
                [disabled]="disabled"
                [compareWith]="compareOgg"
                [ngClass]="{ 'input-error': invalid }"
                placeholder="{{testo}}">

        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

        <input *ngIf="ricerca"
               matInput
               class="inputSearch"
               type="text"
               [ngClass]="{ 'loading': loading }"
               [(ngModel)]="valoreInput"
               [placeholder]="'Cerca'"
               (keyup)="inputFilter()">

        <mat-option *ngIf="clean" [value]="null"
                    class="clear"
                    [ngClass]="{ 'loading': loading }">
            <i class="fas fa-times"></i>
        </mat-option>

        <mat-option *ngFor="let select of _lista | filterInput:valoreInput"
                    [ngClass]="{ 'loading': loading }"
                    [value]="label ? select : select.value">
            {{label ? select[label] : select.viewValue}}
        </mat-option>
    </mat-select>

  </div>

<div class="dropdown-container" *ngIf="!multiple && empty">
    <mat-select [value]="selectedValue"
                (valueChange)="defaultSet($event)"
                [disabled]="disabled"
                [compareWith]="compareOgg"
                [ngClass]="{ 'input-error': invalid }"
                placeholder="{{testo}}">
        <mat-option *ngFor="let select of _lista | filterInput:valoreInput"
                    [value]="label ? select : select.value">
            {{label ? select[label] : select.viewValue}}
        </mat-option>
    </mat-select>
  </div>


<div class="dropdown-container" *ngIf="multiple">
  <mat-select #dropdown
              multiple
              [value]="valore"
              (valueChange)="valueChange($event)"
              [disabled]="disabled"
              [compareWith]="compareOgg"
              [ngClass]="{ 'input-error': invalid }"
              placeholder="{{testo}}">

    <input *ngIf="ricerca"
           matInput
           class="inputSearch"
           type="text"
           [ngClass]="{ 'input-error': invalid }"
           [(ngModel)]="valoreInput"
           [placeholder]="'Cerca'"
           (keyup)="inputFilter()">

    <mat-option *ngIf="selectAll"
                class="select-all"
                [value]="'all'"
                (click)="setSelectAll(ev)"
                #ev>
      {{!_selectAll ? 'Seleziona Tutti' : 'Deseleziona Tutti'}}
    </mat-option>

    <mat-option *ngFor="let select of _lista | filterInput:valoreInput"
                [value]="select.value">
      {{select.viewValue}}
    </mat-option>

  </mat-select>

</div>




