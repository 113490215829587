import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {SdgLibService} from "sdg-lib";


@Injectable({
  providedIn: 'root'
})
export class Oauth2TokenInterceptor implements HttpInterceptor {

    constructor(private keycloakService: KeycloakService,
                private sharedLib: SdgLibService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //this.keycloakService.getToken().then(t => this.getUtenti(t)).catch(e => this.getUtenti(this.shared.token));
    const token = this.sharedLib.token;
    // this.keycloakService.getToken().then(t => token);
   // console.log("Oauth2TokenInterceptor init");
    //console.log(token.valueOf());
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(request);
  }
}
