import {Injectable} from '@angular/core';

import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConfigService} from "./config/config.service";
import {MsgService} from "./utils/msg.service";

@Injectable({
  providedIn: 'root'
})
export class ApiRestKeycloakService {

  //Set on ConfigService
  set apiRestKeycloakLink(value: string) {
    this.linkRest = value;
  }

  set redirectUrlLink(value: string) {
    this.redirectUrl = value;
  }

  private linkRest: string;
  private redirectUrl: string;

  // 'Access-Control-Allow-Origin': '*',
  private headerBase = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Bearer '

  });

  private headerLogin = new HttpHeaders({
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': 'http://localhost:4200'
  });


  private httpSenzaInterceptor;


  constructor(protected http: HttpClient,
              private configUserService: ConfigService,
              private handler: HttpBackend,
              private msgService: MsgService) {

    //SABINA: USO QUESTA ISTANZA DELL'HTTP CLIENT QUANDO NON VOGLIO IL BEARER TOKEN INIETTATO DALL'INTERCEPTOR "Oauth2TokenInterceptor"
    this.httpSenzaInterceptor = new HttpClient(handler);
    // this.linkRest = 'http://172.16.0.120:8080/admin/realms/SDGKeycloak';


    // this.linkRest = environment.test ? 'http://localhost:3000/' : this.configUserService.configuration.apiRestLink;
  }

  login(url: string, username: string, pwd: string): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const credential = {
      username: username,
      password: pwd
    };
    return this.httpSenzaInterceptor.post(_url, JSON.stringify(credential), {headers: this.headerLogin})
        .pipe(
            catchError(error => throwError(error.error))
        );
  }

  getSenzaHeader(url: string): Observable<any[]> {
    const _url = `${this.linkRest}${url}`;
    return this.http.get<any[]>(_url)
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  getWithHeader(url: string, header?: HttpHeaders): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const _header = header ? header : this.headerBase;
    return this.http.get<any>(_url, {headers: _header})
        .pipe(
            catchError(error => this.setError(error))
        );
  }



  getPdf(url: string): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const header = new HttpHeaders({'Content-Type': 'application/pdf'});

    return this.http.get(_url, {responseType: 'arraybuffer', headers: header})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  getImage(url: string, params: any[][]): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const header = new HttpHeaders({'Content-Type': 'image/jpeg'});

    let httpParams = new HttpParams();
    params.forEach(param => httpParams = httpParams.set(param[0], param[1]));

    return this.http.get(url, {responseType: 'arraybuffer', headers: header, params: httpParams})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  getWithParams(url: string, params: any[][], header?: HttpHeaders): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    let httpParams = new HttpParams();

    const _header = header ? header : this.headerBase;

    params.forEach(param => httpParams = httpParams.set(param[0], param[1]));

    return this.http.get<any>(_url, {headers: _header, params: httpParams})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  patchUpdloadFile(url: string, utente: {}, valida: {}, base64: any, fileName: string) {
    const _url = `${this.linkRest}${url}`;

    const formData = new FormData();

    // formData.append('valida', JSON.stringify(valida), );

    formData.append('valida', new Blob([JSON.stringify(valida)], {
      type: 'application/json'
    }));

    const st = base64.replace('data:image/png;base64,', '');

    const blob = this.convertBase64ToBlob2(st);
    formData.append('file', blob, fileName);

    return this.http.patch<any>(_url, formData)
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  private convertBase64ToBlob2(b64Data: any) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: 'image/png'});
  }

  postWithBody(url: string, obj: any): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const body = JSON.stringify(obj);
    return this.http.post<any>(_url, body, {headers: this.headerBase})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  postWithHeaderAndBody(url: string, obj: any, header?: HttpHeaders): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const _header = header ? header : this.headerBase;
    const body = JSON.stringify(obj);
    return this.http.post<any>(_url, body, {headers: _header})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  putWithHeaderAndBody(url: string, obj: any, header?: HttpHeaders): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const _header = header ? header : this.headerBase;
    const body = JSON.stringify(obj);
    return this.http.put<any>(_url, body, {headers: _header})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  deleteWithHeaderAndBody(url: string, obj: any, header?: HttpHeaders): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const _header = header ? header : this.headerBase;
    const _body = JSON.stringify(obj);
    return this.http.delete<any>(_url, {headers: _header, body: _body})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  patchWithBody(url: string, obj: any): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const body = JSON.stringify(obj);
    return this.http.patch<any>(_url, body, {headers: this.headerBase})
        .pipe(
            catchError(error => {
              return this.setError(error);
            })
        );
  }

  patchWithParams(url: string, params: any[][]): Observable<any> {
    const _url = `${this.linkRest}${url}`;

    let httpParams = new HttpParams();
    params.forEach(param => param[1] && (httpParams = httpParams.set(param[0], encodeURIComponent(param[1]))));

    return this.http.patch<any>(_url, {}, {headers: this.headerBase, params: httpParams})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  patchSenzaBody(url: string): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    return this.http.patch<any>(_url, {}, {headers: this.headerBase})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  puthWithBody(url: string, obj: any): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    const body = JSON.stringify(obj);
    return this.http.put<any>(_url, body, {headers: this.headerBase})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  putSenzaBody(url: string): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    return this.http.put<any>(_url, {}, {headers: this.headerBase})
        .pipe(
            catchError(error => this.setError(error))
        );
  }


  delete(url: string): Observable<any> {
    const _url = `${this.linkRest}${url}`;
    return this.http.delete<any>(_url, {headers: this.headerBase})
        .pipe(
            catchError(error => this.setError(error))
        );
  }

  private setError(error: any) {
    this.msgService.msgError(error, "keycloak");
    return throwError(error);
  }
}

