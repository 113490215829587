import {Piatto} from "./Piatto";

export class DettaglioNC {

    piatto?: Piatto;
    quantita?: number;

    constructor(data: any) {
        Object.assign(this, data);
        this.piatto = data.piatto ? new Piatto(data.piatto) : undefined;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.piatto ? this.piatto!.descrizione! : '';

    }

    get value(): DettaglioNC {
        return this;
    }


}
