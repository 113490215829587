import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
//import {pulizieOrdinarieService} from '../../services/chiusura-stanze-home.service';
import {Download} from '../../../../entities/Download';
import {TableName} from '../../../../entities/TableName';
import {TableDocsCols} from './table-docs-cols';
import {TestiService} from '../../../../services/config/testi.service';
import {TableCallback} from '../../../../entities/TableCallback';
import {CallbackTableState} from '../../../../entities/enums/CallbackTableState';
import {CallbackDetailsState} from "../../../../entities/enums/CallbackDetailsState";
import {MatDialog} from "@angular/material/dialog";
import {MsgService} from "../../../../services/utils/msg.service";

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  @Input() codSr!: string;
  @Input() statoId!: number;
  @Input() hidden?: boolean;
  @Input() block?: boolean;
  @Input() listaDocs: Download[];

  @ViewChild('fileInput', { static: false }) fileInput?: ElementRef;
  @ViewChild('filePreview', { static: false }) filePreview?: ElementRef;


  files?: File[];

  tableVoci!: TableName[];

  loading = false;
  testi: any;

  constructor(private msgService: MsgService,
              private dialogService: MatDialog,
              private testiService: TestiService) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit(): void {
    this.tableVoci = this.block ? TableDocsCols.getDocsList(this.testi) : TableDocsCols.getDocsListBlock(this.testi);
  }

  callBackTable(event: TableCallback) {
    switch (event.type) {
      case CallbackTableState.DOWNLOAD:
        this.downloadFile(event.data);
        break;
      case CallbackTableState.DELETE:
        this.openConferma(event.data);
        break;
    }

  }

  downloadFile(file: Download) {
    /*
    this.pulizieOrdinarieService.downloadFile(this.codSr, file.name)
        .subscribe( res => this.createLinkEdDownload(res, file.name) );

     */
  }

  createLinkEdDownload(file: any, name: string) {
    const blob = new Blob([file] );

    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(downloadURL);
    }, 100);
  }

  onChangeFileInput(): void {
    // const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    // this.file = files[0];

    const files: FileList = this.fileInput && this.fileInput.nativeElement.files;

    const arrayFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      arrayFiles.push(files.item(i)!);
    }

    this.files = arrayFiles;
  }

  onClickFileInputButton(): void {
    if(this.fileInput){
      this.fileInput.nativeElement.click();
    }
  }

  upload() {
    /*
    if (this.files && this.files.length) {
      this.ristorazioneService.setLoading(true);


      const obs$: Observable<any> = this.files.length > 1 ?
          this.pulizieOrdinarieService.uploadDocumenti(this.codSr, this.files) : this.pulizieOrdinarieService.uploadDocumento(this.codSr, this.files[0]);

      obs$
          .subscribe(
              image => {
                this.files = undefined;
                this.getAllDocumenti(this.codSr);
              },
              () => this.ristorazioneService.setLoading(false),
              () => {
                this.ristorazioneService.setLoading(false);
              });
    }
    */
  }

  cancel() {
    this.files = undefined;
  }

  //TODO Verifica se avviene l'aggiornamento della tabella dopo l'eliminazione del documento
  private openConferma(file: Download) {
    const msg = this.testi.confermaEliminaDocumento + file.name + '?';
    this.msgService.popupConfermaPopup({type: CallbackDetailsState.DELETE, msg: msg})!
        .subscribe( res => {
          res.type == CallbackDetailsState.DELETE && this.deleteDocumento(file.name);
        } );
  }

  private deleteDocumento(fileName: string) {
    /*
    this.pulizieOrdinarieService.deleteDocumento(this.codSr, fileName)
        .subscribe( res => {
          this.getAllDocumenti(this.codSr);
        });

     */
  }

}
