
export class TipoPiatto{

    id?: number;
    descrizione?: string;
    idTipoPasto:number;

    constructor(data: any) {
        this.id = data.id ? data.id : 0;
        this.descrizione = data.descrizione;
        this.idTipoPasto= data.idTipoPasto ? data.idTipoPasto : 0;
    }


    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }

    get value(): TipoPiatto {
        return this;
    }

}

