import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {Utente} from "../entities/Utente";
import {ApiRestService} from "./api-rest.service";
import {MailWelcome} from '../entities/MailWelcome';
import {Mail} from "../entities/Mail";
import {TestiService} from "./config/testi.service";


@Injectable({
  providedIn: 'root'
})
export class MailService {

    testi;

    constructor(private testiService: TestiService,
                private apiRestService: ApiRestService) {
        this.testi = this.testiService.componentRistorazione;
    }

    getCurrentDateTime(): string{
        let today = new Date();
        let dd = String(today. getDate()). padStart(2, '0');
        let mm = String(today. getMonth() + 1). padStart(2, '0');
        let yyyy = today. getFullYear();
        let hh = today.getHours();
        let min = today.getMinutes();
        return dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min;
    }

    sendWelcomeMail(utente: Utente, password: string): Observable<Mail> {
        const url = `mail/sendmail`
        const model = new MailWelcome({
            "password":password,
            "userdata": utente.firstName + " " + utente.lastName,
            "username": utente.username,
            "mail": utente.email,
            "date": this.getCurrentDateTime()
        })
        const mail = new Mail({
            "mailTo": [utente.email],
            "templateType": "welcome",
            "mailSubject": this.testi.welcomeMailOggetto,
            "model": model.getOggCreate(),
        })
        return this.apiRestService.postWithBody(url, mail.getOggCreate())
    }
}
