import {Component, HostListener, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';


@Component({
  selector: 'app-keycloak',
  templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public username: string;
  public roles: string[];
  explicitLogout: boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event:any) {
      console.log(this.explicitLogout);
      if (!this.explicitLogout) {
          document.cookie = `ristorazione=true;`;
      }
      //window.location.href = "http://www.google.com";//{domain-name}/auth/realms/{realm-name}/protocol/openid-connect/logout?redirect_uri=encodedRedirectUri";
  }

  constructor(private readonly keycloak: KeycloakService
  ) {}

  public async ngOnInit() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    console.log("InitMfeKeycloak");
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.username = await this.keycloak.getUsername();
      this.roles = this.getRoles();
    }
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.explicitLogout = true;
    document.cookie = `ristorazione=false;`;
    this.keycloak.logout(window.location.origin);
  }

  public isLoggenIn(): Promise<boolean>{
      return this.keycloak.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile>{
      return this.keycloak.loadUserProfile();
  }

  public redirectToProfile(): void {
      this.keycloak.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
      return this.keycloak.getUserRoles();
  }

  public getUsername(): string {
       return this.username;
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
      try {
          const userDetails: KeycloakTokenParsed | undefined = this.keycloak.getKeycloakInstance().idTokenParsed
          return userDetails;
      } catch(e) {
          console.error("Eccezione", e);
          return undefined;
      }
  }


}
