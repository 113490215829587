import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from "rxjs";
import {SdgLibService} from "sdg-lib";


@Injectable({
  providedIn: 'root'
})

export class TestiService {

  allTesti: Map<string, any> = new Map<string, any>();

  get componentRistorazione() { return this.checkTestoEsite('componentRistorazione'); }


    constructor( private http: HttpClient,
                 private handler: HttpBackend,
                 private shared: SdgLibService) {
        this.http = new HttpClient(handler);
    }


    private checkTestoEsite(type: string): any {
        const testi = this.shared.testi;
        for (let t of testi){
            if (t[0] == type){
                return t[1]
            }
        }
        return this.allTesti.has(type) ? this.allTesti.get(type) : {};
    }


  private getArrayTestiItObservable(): Observable<any>[] {
    return [
      //this.jsonRestIt('comuneIt', 'comune'),
      this.jsonRestIt('componentRistorazioneIt', 'componentRistorazione'),
    ];
  }

  private getArrayTestiPgObservable(): Observable<any>[] {
      return [
          this.jsonRestPg('componentRistorazionePg', 'componentRistorazione'),
      ];
  }

  setTesti(lang: string): Observable<any> {
      let array$;
      switch (lang){
          default:
          case "it":
              array$ = this.getArrayTestiItObservable();
              return forkJoin(array$);
              break;
          case "pg":
              array$ = this.getArrayTestiPgObservable();
              return forkJoin(array$);
              break;
      }
  }

  private jsonRestIt(link: string, type: string) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});
    const url = `./assets/testi/it/${link}.json`;
    const urlConVersion = url;
    return this.http.get(urlConVersion, {headers})
        .pipe(
            catchError(error => {
              console.error(`Errore al caricare file testo: ${link}`);
              return of({});
            } ),
        )
        .pipe(
            tap( res => {
              this.allTesti.set(type, res);

            } )
        );
  }

  private jsonRestPg(link: string, type: string) {
      const headers = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});
      const url = `./assets/testi/pg/${link}.json`;
      const urlConVersion = url;
      return this.http.get(urlConVersion, {headers})
          .pipe(
              catchError(error => {
                  console.error(`Errore al caricare file testo: ${link}`);
                  return of({});
              } ),
          )
          .pipe(
              tap( res => {
                  this.allTesti.set(type, res);
                  //console.log(this.allTesti.get("componentUtente"));
              } )
          );
  }



}
