<div class="filter-container">
  <div class="filter-lista">
    <div class="filtro" *ngFor="let filtro of filterTypeList; let i = index">

      <div class="filtro-label">
        {{filtro.label}}
      </div>

      <ng-container [ngSwitch]="filtro.type">

        <div class="input-data" *ngSwitchCase="'text'">
          <ng-container *ngTemplateOutlet="text; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dropdown'">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: false, autoSize: false, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'multSelect'">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: true, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dataDa'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dataA'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'data'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

      </ng-container>

    </div>
  </div>

  <div class="filtro-actions">
    <div class="input-data" *ngIf="filterTypeList.length">
      <ng-container *ngTemplateOutlet="applica"></ng-container>
    </div>

    <div class="input-data" *ngIf="showExport">
      <ng-container *ngTemplateOutlet="excel"></ng-container>
    </div>
  </div>

</div>

<!--TEXT-->
<ng-template #text let-filtro="filtro" let-index="index">

  <app-ble-input [valore]="''"
                 [testo]="filtro.label"
                 (cambioValore)="filterText($event, filtro.param, index)">
  </app-ble-input>

</ng-template>

<!--DROPDOWN-->
<ng-template #dropdown let-filtro="filtro" let-multiple="multiple" let-autoSize="autoSize" let-index="index">
  <app-ble-dropdown [lista]="allList.get(filtro.lista)!"
                    [testo]="filtro.label"
                    [disabled]="!allList.get(filtro.lista)?.length"
                    [clean]="true"
                    [multiple]="multiple"
                    [autoSize]="autoSize"
                    (cambioValore)="filterDropdown($event, filtro.prop, filtro.param, multiple, index)">
    </app-ble-dropdown>
</ng-template>

<!--Data-->
<ng-template #data let-filtro="filtro" let-index="index">

    <app-ble-datepicker [clear]="true"
                        [testo]="filtro.label"
                        (cambioValore)="filterDate($event, filtro.param, index)">
    </app-ble-datepicker>

</ng-template>

<!--EXCEL-->
<ng-template #excel>
  <div class="input-applica">
    <button mat-flat-button
            type="button"
            color="primary"
            (click)="exportExcel()">
      {{testi.download}} <i class="fas fa-file-csv"></i>
    </button>
  </div>

</ng-template>

<!--APPLICA-->
<ng-template #applica>

  <div class="input-applica">
      <button mat-flat-button
              type="button"
              color="primary"
              [disabled]="!filtroCambiato"
              (click)="applicaFilter()">
        {{testi.applica}}
      </button>
  </div>

</ng-template>


