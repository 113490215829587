import {RouterModule, Routes} from '@angular/router';
import {ConfigResolver} from "../services/config/configResolver";
import {NgModule} from "@angular/core";
import {LoggedGuard} from "../services/config/LoggedGuard";


export const FLIGHTS_ROUTES: Routes = [
    { path: '',
        canActivate: [LoggedGuard],
        resolve: { message: ConfigResolver },
        children: [
            {path: '', redirectTo: 'ristorazione-app', pathMatch: 'full' },
            {path: 'ristorazione-app',
                loadChildren: () => import('./sdg/ristorazione-app/ristorazione-app.module').then(m => m.ristorazioneAppModule),
            }
        ],

        runGuardsAndResolvers: 'always'
    },
];


@NgModule({
    imports: [RouterModule.forChild(FLIGHTS_ROUTES)],
    exports: [RouterModule],
    providers: [ConfigResolver]
})
export class ristorazioneRouting { }


