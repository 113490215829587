import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Pipe,
    PipeTransform,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SharedFunction} from '../../../services/utils/SharedFunction';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {FormBuilder} from "@angular/forms";


@Component({
  selector: 'app-ble-dropdown',
  templateUrl: './ble-dropdown.component.html',
  styleUrls: ['./ble-dropdown.component.scss']
})
export class BleDropdownComponent implements OnInit, OnChanges {

  @Input() valore?: any;
  @Input() lista?: any[] = [];
  @Input() label?: string;
  @Input() invalid? = false;
  @Input() testo?: string;
  @Input() disabled ? = false;
  @Input() ricerca = true;
  @Input() clean ? = false;
  @Input() multiple ? = false;
  @Input() selectAll ? = false;
  @Input() loading = false;
  @Input() children? = false;
  @Input() lista2?: any[] = [];
  @Input() tooltip? = false;
  @Input() autoSize?: boolean = false;
  @Input() divSize?: any;
  @Input() autocomplete?: boolean = true;

  @Output() cambioValore = new EventEmitter();

  @ViewChild('dropdown', { static: false }) dropdown!: MatSelect;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (this.autoSize && !this.divSize) {
      //Popup-Size
      this.screenWidth = (window.innerWidth * 0.8) <= 900 ? (window.innerWidth * 0.8) - 76 : 900 - 76;
      //Label Size
      this.labelSize = (this.screenWidth * 0.3) <= 250 ? (this.screenWidth * 0.3) : 250;
      //DrowDown Size
      this.dropdownSize = (this.screenWidth - this.labelSize) <= 600 ? (this.screenWidth - this.labelSize) : 600;
    if (this.autoSize && this.divSize) {
      //Popup-Size
      this.screenWidth = window.innerWidth * this.divSize;
      //Label Size
      this.labelSize = (this.screenWidth * 0.3) <= 250 ? (this.screenWidth * 0.3) : 250;
      //DrowDown Size
      this.dropdownSize = (this.screenWidth - this.labelSize) <= 600 ? (this.screenWidth - this.labelSize) : 600;
    }
  }
  }

  screenWidth: number;
  labelSize: number;
  dropdownSize: number;


  _lista: any[] = [];
  _preLista: any[] = [];

  valoreInput?: string;

  setting?: boolean;
  empty=false;

  selected: any;

  _selectAll = false;

  selectedValue: any;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (this.autoSize) {
      this.onResize();
    }
  }

  ngAfterViewInit() {

  }

  stringSize(text: string, size: number | undefined){
        if (text) {
            if (size && text.length > size && text.length > 0) {
                //text = text.substring(0, size);
                //text = text + "...";
            }
        }
        return text;
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.lista && this.lista.length == 1 && this.autocomplete){
      this.valore = this.lista[0];
      this.cambioValore.emit(this.lista[0]);
    }
    if (this.lista && changes['lista'] ) {
      this._lista = this.lista;
      if (this.lista != this._preLista){
        this._preLista = this.lista;
        this.valore = undefined;
      }
      if (this.children && JSON.stringify(this.lista)===JSON.stringify(this.lista2)) {
        this.selectedValue = this.lista[0].value;
        if (this.invalid) {
          setTimeout(() => {
            this.valueChange(this.lista![0].value);
          });
        }
        this.empty = true;
      } else {
        this.empty=false;
        if (this.valore == "emptyItem" && !this.invalid){
          setTimeout(() => {
            this.valueChange(undefined);
          });
        }
      }
    }
    if (changes.valore && changes.valore.firstChange == true && changes.valore.currentValue){
      this.valore = changes.valore.currentValue;
      this.valueChange(changes.valore.currentValue);
    }
  }


  compareOgg(ogg1: any, ogg2: any): boolean {
    return SharedFunction.objectCompare(ogg1, ogg2);
    // return ogg1 === ogg2;
  }

  getToolTip(selected: any){
    return selected.viewValue;
  }

  valueChange(event: any | undefined) {
    if (event != null) {
      this.cambioValore.emit(event);
    } else {
      this.cambioValore.emit([]);
    }
  }

  defaultSet(event: any | undefined) {

  }

  inputFilter() {
}

  setSelectAll(ev: MatOption) {
    let array;

    if (!this._selectAll) {
      this._selectAll = true;
      this.dropdown.options.forEach( item => item.select() );
      array = [...this.dropdown.value];
      array.shift();
    } else {
      this.dropdown.options.forEach( item => item.deselect() );
      array = [...this.dropdown.value];
      this._selectAll = false;
    }
    this.cambioValore.emit(array);
  }
}

@Pipe({
  name: 'filterInput',
  pure: false
})
export class FilterInput implements PipeTransform {
  transform(items: any[], filter: string | undefined): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item['viewValue'].toLocaleLowerCase().includes(filter.toLocaleLowerCase() ));
  }
}
