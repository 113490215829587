import {NgModule} from '@angular/core';
import {KeycloakAngularModule} from 'keycloak-angular';
import {AuthComponent} from './auth.component';
import {CommonModule} from "@angular/common";

//check-sso | login-required



@NgModule({
  declarations: [AuthComponent],
  imports: [CommonModule, KeycloakAngularModule],
  exports: [
      AuthComponent
  ],
  bootstrap: [AuthComponent]
})
export class AuthModule {}
