<div class="dragdrop-container mat-elevation-z1" [ngStyle]="{'max-height.px': DragDropMaxHeight}">

    <!-- DA ASSEGNARE -->
    <div class="col-container">
        <h2>{{testi.assegnabili}}</h2>
        <div style="flex-direction:row;overflow-y:auto;overflow-x:hidden;"
                cdkDropList
                #htmlDaAssegnareList="cdkDropList"
                [cdkDropListData]="listaDaAssegnare"
                [cdkDropListConnectedTo]="[htmlAssegnatiList]"
                class="drop-contanier"
                [cdkDropListDisabled]="disable"
                (cdkDropListDropped)="drop($event, 'listaDaAssegnare')"
                >

            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip style="width:95%;"
                          *ngFor="let item of listaDaAssegnare"
                          [matTooltip]="item.viewValue"
                          [matTooltipDisabled]="item.viewValue?.length < 25"
                          cdkDrag>
                    {{item.viewValue}}
                </mat-chip>
            </mat-chip-list>

        </div>
    </div>
    <!-- / DA ASSEGNARE -->

    <!-- ASSEGNATI -->
    <div class="col-container mat-elevation-z1">
        <h2>{{testi.assegnati}}</h2>
        <div style="flex-direction:row;overflow-y:auto;overflow-x:hidden;"
                cdkDropList
                #htmlAssegnatiList="cdkDropList"
                [cdkDropListData]="listaAssegnati"
                [cdkDropListConnectedTo]="[htmlDaAssegnareList]"
                [cdkDropListDisabled]="disable"
                class="drop-contanier"
                (cdkDropListDropped)="drop($event, 'listaAssegnati')"
                >

            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip style="width:95%;"
                          color="primary"
                          selected
                          *ngFor="let item of listaAssegnati"
                          [matTooltip]="item.viewValue"
                          [matTooltipDisabled]="item.viewValue?.length < 25"
                          cdkDrag>
                    {{item.viewValue}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <!-- / ASSEGNATI -->

</div>
