import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ble-input',
  templateUrl: './ble-input.component.html',
  styleUrls: ['./ble-input.component.scss']
})
export class BleInputComponent implements OnInit {

  @Input() valore?: string | any;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() hidden ? = false;
  @Input() max? = 4000;

  @Output() cambioValore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.valore && typeof this.valore != 'string'){
      for (let item of Object.entries(this.valore)){
        if (typeof item[1] === 'string') {
          this.valore = item[1];
        }
      }
    }
  }

  inputChange() {
    this.cambioValore.emit(this.valore);
  }
}
