<!-- Input Number -->
<div class="input-container" *ngIf="!keys">
  <input type="number"
         class="inputStandard"
         matInput
         step="1"
         [(ngModel)]="_valore"
         [ngClass]="{'input-error': invalid }"
         [disabled]="block ? block : false"
         (keyup)="inputChange()"
         [placeholder]="testo ? testo : ''">

  <div class="input-unit" *ngIf="unit">
    ({{unit}})
  </div>
</div>
<div class="input-container" *ngIf="keys">
  <button mat-flat-button
          class="button"
          type="button"
          color="primary"
          [disabled]="block"
          (click)="minus()">
    <fa-icon [icon]="faMinus"></fa-icon>
  </button>
  <input type="number"
         class="no-border"
         matInput
         step="1"
         [(ngModel)]="_valore"
         [ngClass]="{'input-error': invalid }"
         [disabled]="block ? block : false"
         (keyup)="inputChange()"
         [placeholder]="testo ? testo : ''">
  <div class="input-unit" *ngIf="unit">
    ({{unit}})
  </div>
  <button mat-flat-button
          class="button"
          type="button"
          color="primary"
          [disabled]="block"
          (click)="plus()">
    <fa-icon [icon]="faPlus"></fa-icon>
  </button>
</div>
