<div class="footerForm" *ngIf="!confPopup">
    <!-- Originali -->
    <div *ngIf="!cancelAsReset">
        <button *ngIf="showCancel  && !blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="button"
                color="accent"
                (click)="cancel.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
        <button *ngIf="showCancel  && blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="button"
                color="accent"
                [disabled]="blockCancel"
                (click)="cancel.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
    </div>
    <div *ngIf="cancelAsReset">
        <button *ngIf="showCancel  && !blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="reset"
                color="accent"
                (click)="reset.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
        <button *ngIf="showCancel  && blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="reset"
                color="accent"
                [disabled]="blockCancel"
                (click)="reset.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
    </div>
    <button *ngIf="showDelete && !blockDelete"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="disabled"
            (click)="delete.emit()">
        <div *ngIf="textDelete">{{textDelete}}</div> <div *ngIf="!textDelete"><i class="fas fa-trash-alt"></i>{{testi.delete}}</div>
    </button>
    <button *ngIf="showDelete && blockDelete"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="blockDelete"
            (click)="delete.emit()">
        <div *ngIf="textDelete">{{textDelete}}</div> <div *ngIf="!textDelete"><i class="fas fa-trash-alt"></i>{{testi.delete}}</div>
    </button>

    <button *ngIf="showSave && !blockSave"
            style="{{saveStyle}}"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="disabled"
            (click)="saveForm.emit()">
        <div *ngIf="textSave">{{textSave}}</div> <div *ngIf="!textSave"><i class="fas fa-save"></i>&nbsp;&nbsp;{{testi.salva}}</div>
    </button>
    <button *ngIf="showSave && blockSave"
            style="{{saveStyle}}"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="blockSave"
            (click)="saveForm.emit()">
        <div *ngIf="textSave">{{textSave}}</div> <div *ngIf="!textSave"><i class="fas fa-save"></i>&nbsp;&nbsp;{{testi.salva}}</div>
    </button>

</div>
<div class="footerFormPopup" *ngIf="confPopup">
    <!-- Originali -->
    <div *ngIf="!cancelAsReset">
        <button *ngIf="showCancel  && !blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="button"
                color="accent"
                (click)="cancel.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
        <button *ngIf="showCancel  && blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="button"
                color="accent"
                [disabled]="blockCancel"
                (click)="cancel.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
    </div>
    <div *ngIf="cancelAsReset">
        <button *ngIf="showCancel  && !blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="reset"
                color="accent"
                (click)="reset.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
        <button *ngIf="showCancel  && blockCancel"
                style="{{cancelStyle}}"
                mat-flat-button
                type="reset"
                color="accent"
                [disabled]="blockCancel"
                (click)="reset.emit()">
            <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
        </button>
    </div>
    <button *ngIf="showDelete && !blockDelete"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="disabled"
            (click)="delete.emit()">
        <div *ngIf="textDelete">{{textDelete}}</div> <div *ngIf="!textDelete"><i class="fas fa-trash-alt"></i>{{testi.delete}}</div>
    </button>
    <button *ngIf="showDelete && blockDelete"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="blockDelete"
            (click)="delete.emit()">
        <div *ngIf="textDelete">{{textDelete}}</div> <div *ngIf="!textDelete"><i class="fas fa-trash-alt"></i>{{testi.delete}}</div>
    </button>

    <button *ngIf="showSave && !blockSave"
            style="{{saveStyle}}"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="disabled"
            (click)="saveForm.emit()">
        <div *ngIf="textSave">{{textSave}}</div> <div *ngIf="!textSave"><i class="fas fa-save"></i>&nbsp;&nbsp;{{testi.salva}}</div>
    </button>
    <button *ngIf="showSave && blockSave"
            style="{{saveStyle}}"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="blockSave"
            (click)="saveForm.emit()">
        <div *ngIf="textSave">{{textSave}}</div> <div *ngIf="!textSave"><i class="fas fa-save"></i>&nbsp;&nbsp;{{testi.salva}}</div>
    </button>

</div>
