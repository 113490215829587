import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import {TableName} from '../../../entities/TableName';
import {PageEvent} from '@angular/material/paginator';
import {TestiService} from '../../../services/config/testi.service';
import {TableCallback} from '../../../entities/TableCallback';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit, OnChanges {

  @Input() tableDataHeader!: TableName[];
  @Input() centerTitle?: number = 0;
  @Input() tableData: any[] = [];
  @Input() tableType?: string;
  @Input() admin?: boolean;
  @Input() expand = false;
  @Input() buttonAdd = true;
  @Input() disableAdd = false;
  @Input() buttonAddName?: string;
  @Input() buttonFunc? = false;
  @Input() buttonFuncName?: string;
  @Input() cleanName = false;
  @Input() emptyMessage?: string;
  @Input() buttonAggiorna = false;
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() noRowPattern? = false;
  @Input() rowResize? = true;
  @Input() colorSelected?: string;
  @Input() rows = 1;
  @Input() dataKey = 'id';
  @Input() filterHeader?: string;
  @Input() filterHeaderList?: any[];
  @Input() filterDataList?: Map<string, any[]>;
  @Input() pageIndex?: number;
  @Input() totalPageslazy: number|undefined;
  @Input() loading = false;

  @Output() callBackPaginator: EventEmitter<PageEvent> = new EventEmitter();
  @Output() callBack = new EventEmitter<TableCallback>();

  @ViewChild('table', { static: true }) table!: ElementRef;

  _tableData: any[] = [];

  filterSelected: any[] = [];

  testiIntervento: string;

  constructor(private testiService: TestiService) {
    this.testiIntervento = 'Interventi';
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['tableData'] && (this._tableData = this.tableData);
  }

  setFilter(event: any) {
    switch (event.type) {
      case 'print':
        break;
      case 'filterData':
        this.callBack.emit(event);
        // this.filterSelected = [...event.filter];
        break;
    }
  }

}
