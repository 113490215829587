<div class="filter-container" *ngIf="filterHeader">

<!-- filtro base gestito dal filter type array presente nei singoli nei file *cols.ts -->
  <app-table-header-filter *ngIf="filterHeader == 'standard'"
                           [filterTypeList] = filterTypeList
                           [allList]="allList"
                           (callBack)="callBack.emit($event)">
  </app-table-header-filter>

  <app-table-header-filter *ngIf="filterHeader == 'ristorazione'"
                           [filterTypeList] = filterTypeList
                           [allList]="allList"
                           (callBack)="callBack.emit($event)">
  </app-table-header-filter>

  <app-table-header-filter *ngIf="filterHeader == 'stanze'"
                           [filterTypeList] = filterTypeList
                           [allList]="allList"
                           (callBack)="callBack.emit($event)">
  </app-table-header-filter>
</div>






<div class="table-header" *ngIf="!buttonAdd">
  <div *ngIf="!cleanName" class="table-header-title">
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{testi.lista + ' ' + tableType}}
  </div>
  <div *ngIf="cleanName" class="table-header-title">
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{tableType}}
  </div>
  <div class="input-aggiorna" *ngIf="buttonAggiorna">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.AGGIORNA)">
      <i class="fas fa-redo-alt"></i> {{testi.aggiorna}}
    </button>

  </div>
</div>


<div class="table-header" *ngIf="buttonAdd">

  <div *ngIf="!cleanName" class="table-header-title-add">
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{testi.lista + ' ' + tableType}}
  </div>
  <div *ngIf="cleanName" class="table-header-title-add">
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{tableType}}
  </div>

  <div class="input-aggiorna" *ngIf="buttonAggiorna">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.AGGIORNA)">
      <i class="fas fa-redo-alt"></i> {{testi.aggiorna}}
    </button>
  </div>

  <div class="input-function" *ngIf="buttonFunc">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.FUNCTION)">
       {{buttonFuncName}}
    </button>
  </div>

  <div class="input-add" *ngIf="!buttonAddName">
    <button *ngIf="!disableAdd"
            mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      <i class="fas fa-plus"></i> {{testi.nuovo}}
    </button>
  </div>
  <div class="input-add" *ngIf="buttonAddName">
    <button *ngIf="!disableAdd"
            mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.NUOVO)">
       {{buttonAddName}}
    </button>
  </div>
  <div class="input-add" *ngIf="!buttonAddName">
    <button *ngIf="disableAdd"
            mat-flat-button
            type="button"
            color="black"
            style="background-color: darkgrey;"
            [disabled]="true"
            [hidden]="false"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      <i class="fas fa-plus"></i> {{testi.nuovo}}
    </button>
  </div>
  <div class="input-add" *ngIf="buttonAddName">
    <button *ngIf="disableAdd"
            mat-flat-button
            type="button"
            color="black"
            style="background-color: darkgrey;"
            [disabled]="true"
            [hidden]="false"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      {{buttonAddName}}
    </button>
  </div>

</div>

