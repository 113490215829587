import packageInfo from '../../package.json';

export const environment = {
  production: true,
  appVersion: packageInfo.version,
  keycloak: {
    realm: "SDGKeycloak",
    url: "http://172.16.0.120:8080",
    clientId: "sdg_infra_frontend",
    onLoad: "login-required",
    checkLoginIframe: false
  }
};
