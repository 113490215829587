<ng-container [ngSwitch]="formType.input">

    <!-- Input Text -->
    <div class="inputForm" *ngSwitchDefault>
        <app-ble-input [valore]="value"
                       [testo]="formType.text"
                       [block]="formType.block"
                       [hidden] = "formType.hidden"
                       [invalid]="control.invalid"
                       [max]="formType.max"
                       (cambioValore)="writeValue($event)">
        </app-ble-input>
    </div>

    <!-- Input Icon -->
    <div class="inputForm" *ngSwitchCase="'icon'">
        <app-ble-icon [valore]="value"
                      [prop]="formType.prop"
                      [testo]="formType.text"
                      [block]="formType.block"
                      [width]="formType.width"
                      [height]="formType.height"
                      [invalid]="control.invalid"
                      (cambioValore)="writeValue($event)">
        </app-ble-icon>
    </div>

    <!-- Input Time -->
    <div class="inputForm" *ngSwitchCase="'time'">
        <app-ble-input-time [valore]="value"
                            [testo]="formType.text"
                            [block]="formType.block"
                            [invalid]="control.invalid"
                            (cambioValore)="writeValue($event)">
        </app-ble-input-time>
    </div>

    <!-- Input Number -->
    <div class="inputForm" *ngSwitchCase="'number'">
        <app-ble-input-number [valore]="value"
                              [testo]="formType.text"
                              [block]="formType.block"
                              [unit]="formType.unit"
                              [hidden] = "formType.hidden"
                              [invalid]="control.invalid"
                              [keys]="formType.keys"
                              (cambioValore)="writeValue($event)">
        </app-ble-input-number>
    </div>

    <!-- textarea -->
    <div class="inputForm" *ngSwitchCase="'textarea'">
        <app-ble-input-textarea [valore]="value"
                                [testo]="formType.text"
                                [block]="formType.block"
                                [rows]="formType.rows || 2"
                                [cols]="formType.cols || 30"
                                [msgForbidden]="msgForbidden"
                                [hidden] = "formType.hidden"
                                [invalid]="control.invalid"
                                (cambioValore)="writeValue($event)">
        </app-ble-input-textarea>
    </div>

    <!-- Radio Verticale -->
    <div class="inputForm" *ngSwitchCase="'radioV'">
        <app-ble-radio    [valore]="value"
                                [testo]="formType.text"
                                [block]="formType.block"
                                [list]="formType.valori || []"
                                [orientation]="'verticale'"
                                [hidden] = "formType.hidden"
                                [invalid]="control.invalid"
                                (cambioValore)="writeValue($event)">
        </app-ble-radio>
    </div>

    <!-- multiSelect -->
    <div class="inputForm" *ngSwitchCase="'multselect'">
        <app-ble-dropdown [valore]="value"
                          [lista]="dropdownList"
                          [testo]="formType.text"
                          [disabled]="formType.block || !dropdownList?.length"
                          [invalid]="control.invalid"
                          [hidden] = "formType.hidden"
                          [multiple]="true"
                          (cambioValore)="writeValue($event)">
        </app-ble-dropdown>
    </div>

    <!-- dropdown -->
    <div class="inputForm" *ngSwitchCase="'dropdown'">
        <app-ble-dropdown [valore]="value"
                          [lista]="dropdownList!"
                          [lista2]="dropdownList2!"
                          [testo]="formType.text"
                          [children]="formType.children"
                          [disabled]="formType.block || !dropdownList?.length"
                          [clean]="!formType.required"
                          [autoSize]="formType.autoSize"
                          [divSize]="formType.divSize"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-dropdown>
    </div>

    <!-- dragdrop -->

    <div class="inputForm" *ngSwitchCase="'dragdrop'">
        <app-dettaglio-dragdrop [listaAssegnati]="dropdownList2!"
                                [listaDaAssegnare]="dropdownList!"
                                [disable]="formType.block!"
                                [hidden] = "formType.hidden"
                                [DragDropMaxHeight]="DragDropMaxHeight"
                                (cambioValore)="writeValue($event)">
        </app-dettaglio-dragdrop>

    </div>

    <!-- check -->
    <div class="inputForm" *ngSwitchCase="'check'">
        <app-ble-checkbox [valore]="value"
                          [testo]="formType.text"
                          [block]="formType.block"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-checkbox>
    </div>

    <!-- slide -->
    <div class="inputForm" *ngSwitchCase="'slide'">
        <app-ble-slide [valore]="value"
                       [testo]="formType.text"
                       [block]="formType.block"
                       [hidden] = "formType.hidden"
                       [invalid]="control.invalid"
                       (cambioValore)="writeValue($event)">
        </app-ble-slide>
    </div>

    <!-- date -->
    <app-ble-datepicker *ngSwitchCase="'date'"
                        [valore]="value"
                        [invalid]="control.invalid"
                        [testo]="formType.text"
                        [block]="formType.block"
                        [hidden] = "formType.hidden"
                        [clear] = "formType.clear"
                        (cambioValore)="writeValue($event)">
    </app-ble-datepicker>

    <!-- datetime -->
    <app-ble-datetimepicker *ngSwitchCase="'datetime'"
                        [valore]="value"
                        [invalid]="control.invalid"
                        [testo]="formType.text"
                        [block]="formType.block"
                        [hidden] = "formType.hidden"
                        [clear] = "formType.clear"
                        (cambioValore)="writeValue($event)">
    </app-ble-datetimepicker>


    <!-- Chip List -->
    <app-chip-list *ngSwitchCase="'chipList'"
                   [valore]="value"
                   [testo]="formType.text"
                   [invalid]="control.invalid"
                   [hidden] = "formType.hidden"
                   [block]="formType.block"
                   (cambioValore)="writeValue($event)">
    </app-chip-list>


    <!-- Assegna Prodotto -->
    <app-image-firma *ngSwitchCase="'image'"
                     [valore]="value">
    </app-image-firma>

    <!-- Input password -->
    <div class="inputForm" *ngSwitchCase="'password'">
        <app-ble-password [valore]="value"
                          [block]="formType.block"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-password>
    </div>

    <!-- Documenti -->
    <div class="inputForm" *ngSwitchCase="'documents'">
        <app-docs [codSr]="'Test'"
                  [hidden] = "formType.hidden"
                  [listaDocs]="value"
                 [statoId]="1">
        </app-docs>
    <!--
    <app-docs [codSr]="chiusura-stanze-home!.codsr!"
              [statoId]="chiusura-stanze-home!.stato!.id!">
    </app-docs>
    -->
    </div>

    <!-- TableData <app-table-view *ngIf="formType.tableData"-->
    <div class="inputForm" *ngSwitchCase="'table'">
        <app-table-view *ngIf="formType.tableData"
                        [tableData]="formType.tableData"
                        [tableDataHeader]="formType.tableHeader!"
                        [filterHeader]=""
                        [showFooter]="false"
                        [rowResize]="false"
                        [buttonAggiorna]="false"
                        [emptyMessage]="formType.emptyText"
                        [buttonAdd]="false"
                        [cleanName]="true"
                        [tableType]="formType.tableName"
                        [admin]="true"
                        (callBack)="writeValue($event)">
        </app-table-view>
    </div>
    <div class="inputForm" *ngSwitchCase="'textLabel'" style="color: darkred">
        <span>{{value}}</span>
    </div>

</ng-container>
