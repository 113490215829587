
export class StatoOrdine{

    stato?: string;
    valore?: number;

    constructor(data: any) {
        this.stato = data.stato;
        this.valore = data.valore;
    }


    get viewValue(): string {
        return this.stato ? this.stato : '';
    }

    get value(): string | any {
        return this.valore ? this : this.stato ? this.stato : "";
    }

}

