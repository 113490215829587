import {MyDate} from './MyDate';
import {Paged} from "./Paged";
import {stanza, unitaServizio} from "./pulizieDetails";
import {DescrizioneAttivita} from "./DescrizioneAttivita";
import {CausaliNC} from "./CausaliNC";
import {Tariffe} from "./Tariffe";

export class PuliziePaged extends Paged {
  content: Pulizie[];

  constructor(data: any) {
    super(data);

    this.content = data.content ? data.content.map( (rc: Pulizie) => new Pulizie(rc) ) : [];
  }
}

export class Pulizie {
  id?: number;
  idOrdine?: number;
  dataOrdine?: MyDate;
  stanza?: stanza;
  unitaServizio?: unitaServizio;
  attivita?: DescrizioneAttivita;
  tipoTariffa?: Tariffe;
  giorno?: MyDate;
  descPulStraordinaria?: string;
  numOre?: number;
  note: string;
  ticketId: number;
  userId: number;
  stato: string;
  causaleNonConformita?: CausaliNC;
  statoValidazione: string;
  dataValidazione?: MyDate;
  dataModifica?: MyDate;


  constructor(data: any) {
    this.id = data.id;
    this.idOrdine = data.idOrdine;
    this.dataOrdine = data.dataOrdine ? new MyDate(data.dataOrdine) : undefined;
    this.dataModifica = data.dataModifica ? new MyDate(data.dataModifica) : undefined;
    this.stanza = new stanza(data.stanza);
    this.unitaServizio = new unitaServizio(data.unitaServizio);
    this.attivita = data.attivita ? new DescrizioneAttivita(data.attivita) : undefined;
    this.tipoTariffa = data.tipoTariffa? new Tariffe(data.tipoTariffa) : undefined;
    this.giorno = data.giorno ? new MyDate(data.giorno) : undefined;
    this.descPulStraordinaria = data.descPulStraordinaria;
    this.numOre = data.numOre;
    this.note = data.note;
    this.ticketId = data.ticketId;
    this.userId = data.userId;
    this.stato = data.stato;
    this.causaleNonConformita = data.causaleNonConformita ? new CausaliNC(data.causaleNonConformita) : undefined;
    this.statoValidazione = data.statoValidazione;
    this.dataValidazione = data.dataValidazione ? new MyDate(data.dataValidazione) : undefined;

  }


  get dataOrd(): string {
    return this.dataOrdine ? this.dataOrdine.giornoMeseAnno : '';
  }

  get dataMod(): string {
    return this.dataModifica ? this.dataModifica.giornoMeseAnno : '';
  }

  get dataVal(): string {
    return this.dataValidazione ? this.dataValidazione.giornoMeseAnno : '';
  }

  get dataGiorno(): string {
    return this.giorno ? this.giorno.giornoMeseAnno : '';
  }

  get nomeStanza(): string {
    return this.stanza ? this.stanza.desStanza : '';
  }

  get causaleDesc(): string {
    return this.causaleNonConformita ? this.causaleNonConformita.causaleDesc! : '';
  }

  get causaleDescExt(): string {
    return this.causaleNonConformita ? this.causaleNonConformita.causaleDescExt! : '';
  }

  get attivitaDesc(): string {
    return this.attivita ? this.attivita.attivitaDesc : '';
  }

  get tariffa(): string {
    return this.tipoTariffa ? this.tipoTariffa.tipoTariffa! : '';
  }


  getOggUpdate() {
    return {}
  }

}
