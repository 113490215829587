import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormInputField} from '../../../entities/FormInputField';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TestiService} from '../../../services/config/testi.service';
import {DropdownItem} from '../../../entities/DropdownItem';
import {StatusForm} from "../../../entities/statusForm";

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent implements OnChanges, OnInit, OnDestroy {

  @Input() arrayFormInfo!: FormInputField[];
  @Input() objValue?: any;
  @Input() divSize?: any;
  @Input() selectsGroup?:  Map<string, DropdownItem[]> = new Map<string, DropdownItem[]>();
  @Input() enableFooter = true;
  @Input() groupValidator?: string;
  @Input() showSave?: boolean = true;
  @Input() showCancel?: boolean = false;
  @Input() textCancel?: string;
  @Input() textSave?: string;
  @Input() saveStyle?: string;
  @Input() confPopup?: boolean = false;
  @Input() blockSave?: boolean;
  @Input() cancelStyle?: string;
  @Input() cancelAsReset?: boolean = false;
  @Output() saveForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Output() updateForm = new EventEmitter();
  @Output() formState = new EventEmitter<StatusForm>();

  @Output() callback = new EventEmitter();

  msgs: any[] = [];

  formWidth: number;

  formGroup!: FormGroup;
  testi: any;

  constructor( private formBuilder: FormBuilder,
               private testiService: TestiService) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit() {
    this.initFormGroup(this.arrayFormInfo);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['objValue'] && !!this.formGroup) {
      this.aggiornaForm();
    }
  }

  private initFormGroup(arrayFormInfo: FormInputField[]) {
    this.msgs = [];
    this.formGroup = this.formBuilder.group(this.buildFormControl(arrayFormInfo));
    this.setGroupValidators(this.groupValidator);
    /** update ofter any value change */
    this.formGroup.valueChanges.subscribe(
      data => {
        const objReturn = this.objValue;
        for ( const propertyName in this.formGroup!.value) {
          try {
            objReturn[propertyName] = this.formGroup!.value[propertyName];
          } catch (e) {
            //console.log(e);
          }
        }

        this.updateForm.emit(objReturn);
      }
    );

    /** update ofter status change */
    this.formGroup.statusChanges
      .subscribe( res => this.formState.emit(res) );
    setTimeout( () => this.formState.emit(this.formGroup.status as 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'), 100);
  }

  private aggiornaForm() {
    this.arrayFormInfo.forEach( form => {
      this.formGroup!.get(form.prop)!.setValue(this.objValue[form.prop]);
    });
  }

  private setGroupValidators(groupValidator: any) {
  }

  actionSave() {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }

    this.saveForm.emit(objReturn);
  }

  actionCancel() {
    this.cancel.emit();
  }

  actionReset() {
    this.reset.emit();
  }

  private buildFormControl(arr: FormInputField[]) {
    const group: any = {};

    arr.forEach(item => {
      group[item.prop] = this.objValue[item.prop] == null ? this.formControlNew(item) : this.formControlUpdate(item);
      group[item.prop].setValidators(this.makeValidators(item));
    });

    if (this.objValue.id && !group['id'])  {
      group['id'] = new FormControl({value: this.objValue.id, disabled: true});
    }

    return group;
  }

  private formControlUpdate(item: FormInputField): FormControl {
    return new FormControl({value: this.objValue[item.prop], disabled: item.block});
  }

  private formControlNew(item: FormInputField): FormControl {

    return new FormControl({value: '', disabled: item.block ? item.block : false });
  }

  private makeValidators(item: FormInputField): Validators[] {
    let _array: Validators[] = [];
    item.validator && (_array = item.validator.map(itemVal => { this.msgs.push({prop: item['prop'], msg: itemVal['errorMsg']});
      return itemVal['value'];
    } ));
    item.required && _array.push(Validators.required);

    return _array;
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      // this.formGroup = undefined;
    }
  }

}
