<div class="input-nuovo-container">
  <input type="password"
         class="inputStandard input-pass"
         matInput
         [(ngModel)]="password"
         [ngClass]="{ 'input-error': invalid }"
         [placeholder]="'Nuova Password'"
         (ngModelChange)="changePassword($event)">
</div>

<div class="input-nuovo-container" *ngIf="showConferm">
  <div class="ripeti-label">
    Ripeti Password:
  </div>

  <input type="password"
         class="inputStandard input-pass"
         matInput
         [(ngModel)]="ripete"
         [ngClass]="{ 'input-error': invalid }"
         (ngModelChange)="confermaValore($event)">

  <div class="error-validator" *ngIf="password != ripete && ripete">
    I campi 'Nuova password' e 'Conferma password' non coincidono
  </div>
</div>
