import {Paged} from "./Paged";

export class TipoStruttura {

    id?: number;
    descrizione?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    getOggCreate() {
        return {
            //id: this.id,
            descrizione: this.descrizione!
        };
    }

    getOggUpdate() {
        return {
            id: this.id,
            descrizione: this.descrizione!
        };
    }


    /* servono per essere visualizzati e valorizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): TipoStruttura {
        return this;
    }

}

export class TipoStrutturaPaged extends Paged {
    content: TipoStruttura[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new TipoStruttura(ts)) : [];
    }
}

