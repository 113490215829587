export class CausaliNC {

    id?: number;
    causaleDesc?: string;
    descrizione?: string;
    causaleDescExt?: string;
    tipoCausale?: string;
    tipoControllo?: string;
    riferimento?: string;
    coeffPonderazione?:number;
    indicatore?: string;
    conformita?: number;
    lqa?: number;


    constructor(data: any) {
        this.id = data.id;
        this.causaleDesc = data.causaleDesc;
        this.descrizione = data.descrizione ? data.descrizione : data.causaleDesc;
        this.causaleDescExt = data.causaleDescExt;
        this.tipoCausale = data.tipoCausale;
        this.tipoControllo = data.tipoControllo;
        this.riferimento = data.riferimento;
        this.coeffPonderazione = data.coeffPonderazione;
        this.indicatore = data.indicatore;
        this.conformita = 0;
        this.lqa = data.lqa;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.causaleDesc ? this.causaleDesc : this.descrizione ? this.descrizione : '';
    }

    get value(): CausaliNC {
        return this;
    }

}

export class CausaliSaveNC {

    id: number;

    constructor(data: any) {
        this.id = data.id;
    }
}
