import {Routes} from '@angular/router';
import {AppComponent} from "./app.component";

export const APP_ROUTES: Routes = [
    { 
        path: '', 
        component: AppComponent,
        pathMatch: 'full'
    },
    { 
        path: 'ristorazione',
        loadChildren: () => import('./ristorazione/ristorazione.module')
            .then(m => m.ristorazioneModule)
    }
];
