import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormInputField} from '../../../entities/FormInputField';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TestiService} from '../../../services/config/testi.service';

@Component({
  selector: 'app-form-valida',
  templateUrl: './form-valida.component.html',
  styleUrls: ['./form-valida.component.scss']
})
export class FormValidaComponent implements OnChanges, OnInit, OnDestroy {

  @Input() arrayFormInfo!: FormInputField[];
  @Input() objValue?: any;
  @Input() selectsGroup?: Map<string, any[]> = new Map<string, any[]>();
  @Input() enableFooter = true;

  @Output() saveForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() updateForm = new EventEmitter();
  @Output() formState = new EventEmitter<'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'>();

  @Output() callback = new EventEmitter();

  msgs: any[] = [];

  formGroup?: FormGroup;
  testi;

  constructor( private formBuilder: FormBuilder,
               private testiService: TestiService) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objValue) {
      if (this.objValue) {
        this.msgs = [];
        this.formGroup = this.formBuilder.group(this.buildFormControl(this.arrayFormInfo));
        this.formGroup.valueChanges.subscribe(
          data => this.updateForm.emit(data)
        );
        this.formGroup.statusChanges
          .subscribe( res => this.formState.emit(res) );
        // this.formState.emit(this.formGroup.status);
      } else {
        this.formGroup && this.formGroup.reset();
      }
    }
  }

  actionSave() {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }

    this.saveForm.emit(objReturn);
  }

  actionCancel() {
    this.cancel.emit();
  }

  private buildFormControl(arr: FormInputField[]) {
    const group: any = {};

    arr.forEach(item => {
      group[item.prop] = this.objValue[item.prop] == null ? this.formControlNew(item) : this.formControlUpdate(item);
      group[item.prop].setValidators(this.makeValidators(item));
    });

    if (this.objValue.id && !group['id'])  {
      group['id'] = new FormControl({value: this.objValue.id, disabled: true});
    }

    return group;
  }

  private formControlUpdate(item: FormInputField) {
    return new FormControl({value: this.objValue[item.prop], disabled: item.block});
  }

  private formControlNew(item: FormInputField) {
    return new FormControl({value: '', disabled: item.block ? item.block : false });
  }

  private makeValidators(item: FormInputField): Validators[] {
    let _array: Validators[] = [];
    item.validator && (_array = item.validator.map(itemVal => { this.msgs.push({prop: item['prop'], msg: itemVal['errorMsg']});
      return itemVal['value'];
    } ));
    item.required && _array.push(Validators.required);

    return _array;
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      this.formGroup = undefined;
    }
  }

}
