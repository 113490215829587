import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestiService} from '../../../../services/config/testi.service';
import {MyDate} from '../../../../entities/MyDate';
import {TableCallback} from '../../../../entities/TableCallback';
import {CallbackTableState} from '../../../../entities/enums/CallbackTableState';
import {FilterType} from '../../../../entities/utils/FilterType';

@Component({
  selector: 'app-table-header-filter',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss']
})
export class TableHeaderFilterComponent implements OnInit {

  @Input() filterTypeList: FilterType[] = [];
  @Input() allList: Map<string, any[]> = new Map<string, any[]>();

  @Output() callBack = new EventEmitter<TableCallback>();

  filterSelezionato: any[][] = [];
  filterSelezionatoApplica: any[][] = [];
  filtroCambiato = false;

  showExport = false;

  testi;

  constructor(private testiService: TestiService) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit(): void {
    this.filterSelezionato = this.filterTypeList.map( () => [] );
    this.showExport = this.filterTypeList.some( filter => filter.type === 'excel');
  }

  filterText(event: any, param: string, index: number) {
    this.filterSelezionato[index] = event ? [param, event, event] : [];
    this.filtroCambiato = true;
  }

  filterDropdown(event: any, prop: string, param: string, multiple: boolean, index: number) {

    /* Fine Per DropDown in cascata */
    if (multiple) {
      const selectedList = event.map((item: any) => item[prop] ).join(',');
      this.filterSelezionato[index] = event.length ? [param, selectedList] : [];
    } else {
      if (event[prop] != undefined && event.viewValue != undefined) {
        this.filterSelezionato[index] = event ? [param, event[prop], event.viewValue] : [];
      } else {
        this.filterSelezionato[index] = event ? [param, event] :[];
      }

    }
    this.filtroCambiato = true;
  }

  filterDate(event: Date, param: string, index: number) {
    let myDate = event ? new MyDate(event) : null;
    if (param == 'dataA' && myDate) {
      myDate = myDate.addDays(1);
    }
    this.filterSelezionato[index] = myDate ? [param, myDate.dateSave, myDate.giornoMeseAnno] : [];
    this.filtroCambiato = true;
  }

  applicaFilter() {
    const filter = this.filterSelezionato.filter( item => item.length );
    this.callBack.emit({type: CallbackTableState.FILTER, data: filter});
    this.filtroCambiato = false;
    this.filterSelezionatoApplica = filter;
  }

  exportExcel() {
    this.callBack.emit({type: CallbackTableState.EXCEL, data: this.filterSelezionatoApplica });
  }

}
