import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable, Subject} from 'rxjs';

import {TestiService} from '../config/testi.service';
import {Snackbar} from '../../entities/Snackbar';
import {SharedFunction} from './SharedFunction';
import {PopupConfermaComponent} from '../../shared-modules/popup-conferma/popup-conferma/popup-conferma.component';
import {PopUpConferma} from '../../entities/PopUpConferma';


@Injectable({
  providedIn: 'root'
})
export class MsgService {

  private _msgSnackbar$: Subject<Snackbar> = new Subject();
  get msgSnackbar$(): Subject<Snackbar> { return this._msgSnackbar$; }

  testi: any;

  constructor(private testiService: TestiService,
              private dialog: MatDialog) {
    this.testi = this.testiService.componentRistorazione;
  }

  private reloadTesti(){
    this.testi = this.testiService.componentRistorazione;
  }

  popupConferma(confermaMsg: PopUpConferma): Observable<PopUpConferma> {
    this.reloadTesti();
    return this.openConferma(confermaMsg);
  }

  popupConfermaPopup(confermaMsg: PopUpConferma): Observable<PopUpConferma> {
    this.reloadTesti();
        return this.openConfermaPopup(confermaMsg);
  }

  private openConferma(confermaMsg: PopUpConferma) {
    this.reloadTesti();
    this.dialog.closeAll();
    const dialog = SharedFunction.openPopUpConferma(this.dialog, confermaMsg, PopupConfermaComponent);
    return dialog.afterClosed();
  }

  private openConfermaPopup(confermaMsg: PopUpConferma) {
    this.reloadTesti();
        const dialog = SharedFunction.openPopUpConferma(this.dialog, confermaMsg, PopupConfermaComponent);
        return dialog.afterClosed();
    }

  msgDeleteSucess(typeOgg: string) {
    this.reloadTesti();
    const summary = this.testi.msgDeleteSummary;
    const details = typeOgg + ' ' + this.testi.msgDeleteDeteils;

    this._msgSnackbar$.next( {severity: 'success', summary: summary, detail: details, msg: details} );
  }

  msgMailError() {
    const summary = "Attenzione";
    const details = "Operazione Eseguita con Successo ma non è stato possibile inviare la mail"
    this._msgSnackbar$.next( {severity: 'warn', summary, detail: details, msg: details} );
  }

  msgError(error: any, type?: string) {
    this.reloadTesti();
    const summary = this.testi.errore;
    let details = this.checktypeError(error);
    if (type === "keycloak") {
      details = this.checkmessageError(error);
    }
    if (details.includes("[") && details.includes("]")){
      let str = details.split("[");
      str = str[1].split("]");
      details = str[0];
    }

    this._msgSnackbar$.next( {severity: 'error', summary: summary, detail: details, msg: details} );
  }

  msgStringError(error: string, type?: string) {
    this.reloadTesti();
    const summary = this.testi.errore;

    this._msgSnackbar$.next( {severity: 'error', summary: summary, detail: error, msg: error} );
  }

  msgAlert(msg: string) {
    this.reloadTesti();
    const summary = this.testi.msgAlertSummary;
    const details = msg;

    this._msgSnackbar$.next( {severity: 'warn', summary, detail: details, msg: details} );
  }

  msgSuccess(typeOgg: string, update?: boolean) {
    this.reloadTesti();
    const summary = this.testi.msgSuccessSummary;
    let details = typeOgg + ' ';
    if (update) {
      details = details + this.testi.msgSuccessUpdate;
    } else {
      details = details + this.testi.msgSuccessCreate;
    }
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: details} );
  }

  genericMsgSuccess(typeOgg: string, update?: boolean) {
    this.reloadTesti();
    const summary = this.testi.msgSuccessSummary;
    const details = this.testi.correttamente;
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: details} );
  }

  msg(msg: string, type = 'success') {
    this.reloadTesti();
    const summary = this.testi.msgSuccessSummary;
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: msg} );
  }

  private checktypeError(error: any) {
    this.reloadTesti();
    let msg;
    if (error.error.message) {
      msg = error.error.message;
    } else {
      if (error.message){
        msg = error.message;
      } else {
        msg = this.testi.msgAllarmMessage3;
      }
    }

    return msg;
  }

  private checkmessageError(error: any) {
    this.reloadTesti();
    let msg;
    if (error.error) {
      msg = error.error.errorMessage;
    } else {
      msg = this.testi.msgAllarmMessage3;
    }

    if (msg === "User exists with same username") {
      msg = "Username già presente a sistema"
    }
    if (msg === "User exists with same email") {
      msg = "E-Mail già presente a sistema"
    }
    return msg;
  }

  private checkError(errorMsg: string) {
    this.reloadTesti();
    if (errorMsg.includes('violato vincolo di integrità') ) {
      const vincolo = errorMsg.match(/=\(.*\)/g);
      if (vincolo) {
        const name = vincolo[0].slice(2, -1);
        return `${name} ${this.testi.msgAllarmMessage1}`;
      }
    }

    return this.testi.msgAllarmMessage3;
  }

}
