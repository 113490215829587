<div class="data-container">

  <div class="data-picker-container"
       [ngClass]="{'error-form': invalid}"
       (click)="picker1.open()">

    <input name="datepicker"
           class="input-date"
           [placeholder]="testo"
           [matDatepicker]="picker1"
           [(ngModel)]="valore"
           (focus)="picker1.open()"
           [min]="min"
           [max]="max"
           [ngClass]="{
           'input-error': invalid,
           'input-date-disable': block,
           'input-100': full,
           'input-80': !full
           }"
           [disabled]="true"
           [hidden]="hidden ? hidden: false"
           (ngModelChange)="valueChange($event)">


    <div *ngIf="!hidden" class="icon-container">
      <i class="fas fa-calendar-alt" [ngClass]="{ 'icon-disable': block }"></i>
    </div>

    <mat-datepicker #picker1
                    [disabled]="block"
                    [hidden]="hidden ? hidden: false"
                    [calendarHeaderComponent]="dataPickerHeader"
                    (monthSelected)="getMonth($event, picker1)">
    </mat-datepicker>

  </div>

  <div class="icon-container" *ngIf="valore && clear && !block && !hidden">
    <i  class="far fa-calendar-times" (click)="clearStartDate()"></i>
  </div>

</div>
