<div class="data-container">

  <div class="data-picker-container"
       [ngClass]="{'error-form': invalid}"
       (click)="picker.open()">

    <input class="input-date"
           [placeholder]="testo"
           [ngxMatDatetimePicker]="picker"
           [(ngModel)]="valore"
           (focus)="picker.open()"
           [min]="min"
           [max]="max"
           [ngClass]="{
           'input-error': invalid,
           'input-date-disable': block,
           'input-100': full,
           'input-80': !full
           }"
           [disabled]="true"
           [hidden]="hidden ? hidden: false"
           (ngModelChange)="valueChange($event)">


    <div *ngIf="!hidden" class="icon-container">
      <i class="fas fa-calendar-alt" [ngClass]="{ 'icon-disable': block }"></i>
    </div>

    <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                             [stepMinute]="1" [stepSecond]="60" [touchUi]="false"
                             [enableMeridian]="false" [disableMinute]="false" [hideTime]="false"
                             [disabled]="block ? block : false"
                             [hidden]="hidden ? hidden: false">
      <ng-template>
        <span>OK</span>
      </ng-template>
    </ngx-mat-datetime-picker>

  </div>

  <div class="icon-container" *ngIf="valore && clear && !block && !hidden">
    <i  class="far fa-calendar-times" (click)="clearStartDate()"></i>
  </div>
</div>
