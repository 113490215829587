import {Injectable} from '@angular/core';
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {ConfigResolver} from "../config/configResolver";
import {SdgLibService} from "sdg-lib";
import {KeycloakOnLoad} from "keycloak-js";
import {switchMap} from "rxjs/operators";
import {from} from "rxjs";
import {ApiRestService} from "../api-rest.service";
import {RistorazioneAppService} from "../ristorazione-app.service";


@Injectable({
  providedIn: 'root'
})
export class InitAppService {

  private https = '';
  conf: any;
  refresh: any;

  constructor(private sharedLib: SdgLibService,
              private configREsolver: ConfigResolver,
              private keycloakService: KeycloakService,
              private apiRestService: ApiRestService,
              private ristorazioneService: RistorazioneAppService
              ) {
  }


getJSONConfigurationPromise(): Promise<void> {
    this.https = window.location.href.toString().split(':')[0];

    return new Promise<void>((resolve, reject) => {
      from(this.configREsolver.resolve())
          .pipe((conf)=> this.conf=conf)
          .subscribe( (conf) => {
              this.setObservableToken(conf);
              resolve()} );
    });
  }

  private httpSwitch(config: any): any {
      let k: keyof typeof config;
      for (k in config) {
          if (typeof config[k] == 'string' && config[k].toString().includes('http')) {
              const test = config[k].toString().split(':')[0];
              if (test != this.https) {
                  config[k] = config[k].toString().replace(test, this.https);
              }
          }
      }
      return config;
  }

  private setUserData(token: string) {
      this.sharedLib.token = token;
      this.sharedLib.utente = this.sharedLib.tokenDecoded;
  }

  private setObservableToken(conf:any): void {
      this.conf = conf;
    this.keycloakService.isLoggedIn().then((res: any)=>{
        if (res == true){
            from (this.keycloakService.getToken()).subscribe( token => {
                this.sharedLib.token = token;
                document.cookie = "ristorazione=false;";
                this.sharedLib.utente = this.sharedLib.tokenDecoded;
                this.setUserData(this.keycloakService.getKeycloakInstance().token!);
            });
        }
    });

    this.keycloakService.keycloakEvents$.subscribe( event => {
      if (event.type == KeycloakEventType.OnAuthSuccess) {
        from(this.keycloakService.getToken())
            .subscribe( token => {
              this.sharedLib.token = token;
                document.cookie = "ristorazione=false;";
              this.sharedLib.utente = this.sharedLib.tokenDecoded;
            });
      }
    });
    this.tokenRenew();
  }

    private tokenRenew() {
        clearInterval(this.refresh);
        this.refresh = setInterval(() => this.updateToken(), 1000*60);
    }

    private updateToken(){
      const elapsed = (new Date().getTime() - this.ristorazioneService.getLastActivity().getTime()) / 60000;
      if ( elapsed <= this.conf.sessionTimeout) {
          this.keycloakService.updateToken(1).then((refreshed) => {
              if (refreshed) {
                  console.log("Token Rinnovato", new Date());
                  this.setUserData(this.keycloakService.getKeycloakInstance().token!);
                  this.apiRestService.refreshToken = this.keycloakService.getKeycloakInstance().refreshToken!;
              } else {
                  console.log('Token Ancora Valido ', new Date());
              }
          }).catch(() => {
              console.error('Impossibile Rinnovare il Token ', new Date());
          });
      } else {
          this.keycloakService.logout()
      }
    }

  private initializeKeycloak(keycloak: KeycloakService): Promise<boolean> {
      const onLoad = this.conf.onLoad as KeycloakOnLoad;
      const protocol = this.conf.url.split(':')[0];
      let url = this.conf.url.replace(protocol, this.https);
      return keycloak.init();
  }



}
