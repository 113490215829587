import {TipoMenu} from "./TipoMenu";
import {Dieta} from "./Dieta";
import {Pazienti} from "./Pazienti";
import {Piatti} from "./Piatti";


export class DettaglioOrdine {
  id?:number;
  idTestataOrdine?: number;
  ordineCumulativo?: boolean;
  pazienteId?: number;
  numeroDettaglio?:number;
  presente?: number;
  nominativo?: string;
  tipoMenu?: TipoMenu;
  dieta?: Dieta;
  note?: string;
  cf?: string;
  listaPiatti?: Piatti[];
  paziente?: Pazienti;


  constructor(data: any) {
    this.id = data.id;
    this.ordineCumulativo = data.ordineCumulativo;
    this.numeroDettaglio = data.numeroDettaglio;
    this.presente = data.presente;
    this.idTestataOrdine = data.idTestataOrdine;
    this.pazienteId = data.pazienteId;
    this.nominativo = data.nominativo;
    this.tipoMenu = data.tipoMenu ? new TipoMenu(data.tipoMenu) : undefined;
    this.dieta = data.dieta ? new Dieta(data.dieta) : undefined;
    this.note = data.note;
    this.cf = data.cf;
    //TODO
    this.listaPiatti = data.listaPiatti ? data.listaPiatti : null;
  }

  numDettaglioOrdine() {
    return this.id ? this.id : '';
  }

  getOggCreate(): DettaglioOrdineCreate {
    return {
    }
  }
}

export interface DettaglioOrdineCreate {
  idTestataOrdine?: number;
  pazienteId?: number;
  nominativo?: string;
  tipoMenu?: TipoMenu;
  dieta?: Dieta;
  note?: string;
  cf?: string;
  listaPiatti?: any[];
}
