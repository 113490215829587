import {Paged} from "./Paged";

export class TipoPrenotazione {

    id?: number;
    descrizione?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }





    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): TipoPrenotazione {
        return this;
    }

}

export class TipoPrenotazionePaged extends Paged {
    content: TipoPrenotazione[];

    constructor(data: any) {
        super(data);
        this.content = data.content ? data.content.map((ts: any) => new TipoPrenotazione(ts)) : [];
    }
}

