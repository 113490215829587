import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";

import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap, tap} from "rxjs/operators";

import {ApiRestService} from "../api-rest.service";
import {TestiService} from "./testi.service";
import {environment} from "../../../environments/environment.prod";
import {MsgService} from "../utils/msg.service";
import {SdgLibService} from "sdg-lib";
import {ApiRestKeycloakService} from "../api-rest-keycloak.service";

@Injectable()
export class ConfigResolver implements Resolve<any> {

  testi: any;
  conf: any;

  private _standalone: boolean = false;

  constructor(private apiRestService: ApiRestService,
              private apiRestKeycloakService: ApiRestKeycloakService,
              private msgService: MsgService,
              private testiService: TestiService,
              private http: HttpClient,
              private handler: HttpBackend,
              private sharedLib: SdgLibService) {
    this.http = new HttpClient(handler);
  }

  private setTestiInizialeMsgService() {
    this.msgService.testi = this.testiService.componentRistorazione;
  }

  /*Caricamento Testi*/
  private getConfiguration(): Observable<any> {
      return this.getConfigurationRest().pipe(
          switchMap((data) =>this.testiService.setTesti(this.testi))
      )
  }

  private getConfigurationRest(): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    const url = `./assets/json/configuration.json`;
  //  const urlConVersion = url;
    const urlConVersion = environment.production ? `${url}?v=${environment.appVersion}` : url;

    return this.http.get(urlConVersion, {headers})
      .pipe(
        catchError(error => {
          console.error(`Errore al caricare il file: Configuration.json`);
            return of({});
        }),
      )
      .pipe(
        tap( (conf: any) => {
          this.testi = conf.testi;
          this.apiRestService.apiRestLinkRistorazione = conf.apiRestLinkRistorazione;
          this.apiRestService.apiRestLinkRichieste = conf.apiRestLinkRichieste;
          this.apiRestService.apiRestLinkAnagrafiche = conf.apiRestLinkAnagrafiche;
          this.apiRestKeycloakService.apiRestKeycloakLink = conf.apiRestKeycloakLink;
            this.apiRestKeycloakService.redirectUrlLink = conf.redirectUrl;
          this.conf = conf;
          this.standalone = conf.standalone ? conf.standalone : false;
        } )
      );
  }

  get standalone(): boolean{
      return this._standalone;
  }

  set standalone(value: boolean){
      this._standalone = value;
  }

  get config(): any {
      return this.conf;
  }

    private getConfigurationLib(){
        this.testi= this.sharedLib.conf.testi;
        this.apiRestService.apiRestLinkRistorazione = this.sharedLib.conf.apiRestLinkRistorazione;
        this.apiRestService.apiRestLinkRichieste = this.sharedLib.conf.apiRestLinkRichieste;
        this.apiRestService.apiRestLinkAnagrafiche = this.sharedLib.conf.apiRestLinkAnagrafiche;
        this.apiRestKeycloakService.apiRestKeycloakLink = this.sharedLib.conf.apiRestKeycloakLink;
    }

    resolve(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.sharedLib.token && this.testiService.allTesti.has('componentRistorazione')) {
                return resolve();
            }
            if (this.sharedLib.testi.length != 0 && this.sharedLib.conf.length != 0){
                this.setTestiInizialeMsgService();
                this.getConfigurationLib();
                resolve(this.conf);
            } else {
                this.getConfiguration()
                    .subscribe(
                        conf => {
                            this.setTestiInizialeMsgService();
                            resolve(this.conf);
                        },
                        error => {
                            console.log(error);
                            console.error('Utente Invalido');
                            throwError(new Error('Utente Invalido'));
                            reject();
                        }
                    );
            }
        });
    }


}
