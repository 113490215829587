export class UnitaServizio {

    id: number;
    descUnitaServizio: string;
    usDesc: string;


    constructor(data: any) {
        Object.assign(this, data);
    }

    get value() {
        return this;
    }

    get viewValue() {
        return this.usDesc ? this.usDesc : '';
    }
}
