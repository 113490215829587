import {TableName} from '../../../../entities/TableName';

export class TableDocsCols {

  static getDocsList(testi: any): TableName[] {
    return [
      {name: "Nome", filter: '', order: '', prop: 'name', size: 80},
      {name: "Tipo", filter: '', order: '', prop: 'type', size: 10 },
      {name: '', filter: '', order: '', prop: 'download', size: 5 },
      {name: '', filter: '', order: '', prop: 'cancella', size: 5 },
    ];
  }
  static getDocsListBlock(testi: any): TableName[] {
    return [
      {name: "Nome", filter: '', order: '', prop: 'name', size: 80},
      {name: "Tipo", filter: '', order: '', prop: 'type', size: 10 },
      {name: '', filter: '', order: '', prop: 'download', size: 5 },
      {name: '', filter: '', order: '', prop: 'authCancel', size: 5 },
    ];
  }
}

