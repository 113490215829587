import {Piatto} from "./Piatto";
import {TipoPiatto} from "./TipoPiatto";

export class Piatti{

    id?: number;
    idDettaglioOrdine?:number;
    quantita?:number;
    grammi?: number;
    tipoPiatto?: TipoPiatto;
    piatto?: Piatto;

    constructor(data: any) {
        this.idDettaglioOrdine = data.idDettaglioOrdine ? data.idDettaglioOrdine : 0;
        this.quantita = data.quantita ? data.quantita : null;
        this.grammi = data.grammi ? data.grammi : null;
        this.tipoPiatto = data.tipoPiatto ? new TipoPiatto(data.tipoPiatto) : undefined;
        this.piatto = data.piatto ? new Piatto(data.piatto) : undefined;
    }

    getOggCreate() {
        return {
            idDettaglioOrdine: this.idDettaglioOrdine,
            quantita: this.quantita,
            grammi: this.grammi,
            tipoPiatto: this.tipoPiatto,
            piatto: this.piatto
        }
    }

    get nomePiatto():string {
        return this.piatto ? this.piatto.descrizione! : '';
    }

    get tipoPiattoName():string {
        return this.tipoPiatto ? this.tipoPiatto.descrizione! : '';
    }

    get viewValue(): string{
        return this.piatto ? this.piatto.descrizione! : '';
    }

    get value(): Piatti {
        return this;
    }

}

