import {MyDate} from './MyDate';
import {Role} from './Role';
import * as moment from 'moment';
import {Credentials} from "./Credentials";

export class Utente {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  validityEnd?: MyDate;
  validityStart?: MyDate;
  enabled: boolean;
  roles: Role[];
  note?: string;
  credentials?: Credentials;

  constructor(data: any) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.username = data.username;
    this.email = data.email;
    this.enabled = data.enabled;
    this.note= data.attributes ?  data.attributes.Note : "";

    let dI = data.attributes ? moment(data.attributes.ValidityStart[0], "DD/MM/YYYY") : undefined;
    this.validityStart = dI ? new MyDate(dI) : undefined;

    let dF = data.attributes ? moment(data.attributes.ValidityEnd[0], "DD/MM/YYYY") : undefined;
    this.validityEnd = dF ? new MyDate(dF) : undefined;

    this.roles = data.roles ? data.roles.map((role: any) => new Role(role)) : [];
  }


  get dataFineValidita(): string {
    return new MyDate(this.validityEnd).giornoMeseAnno;
  }

  get dataInizioValidita(): string {
    return new MyDate(this.validityStart).giornoMeseAnno;
  }


  getOggUpdate() {
    return this.getOggCreate();
  }

  getOggCreate(): UtenteCreate {
    return {
      username: this.username!,
      enabled: this.enabled,
      attributes: Object.fromEntries(this.getAttributeUser()),
      realmRoles: this.roles.map(rl => new String(rl.name)),
      firstName: this.firstName!,
      lastName: this.lastName!,
      email: this.email!
    };
  }


   getAttributeUser() : Map<string, string> {
     const m =  new Map<string, string>();
     m.set("ValidityStart", this.dataInizioValidita);
     m.set( "ValidityEnd", this.dataFineValidita);
     m.set( "Note", this.note ? this.note : "");
     return m;

   }

}

export interface UtenteCreate {
  username: string;
  attributes: any ;
  firstName: string;
  lastName: string;
  email: string;
  realmRoles: String[];
  enabled: boolean;
}

export interface UtenteUpdate extends UtenteCreate {
  id: number;
}





