import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ble-password',
  templateUrl: './ble-password.component.html',
  styleUrls: ['./ble-password.component.scss']
})
export class BlePasswordComponent implements OnInit {

  @Input() id: any;
  @Input() valore?: string;
  @Input() block ? = false;
  @Input() invalid ? = false;

  @Output() cambioValore = new EventEmitter();

  testi?: any;

  oldPassword?: string;
  password?: string;
  showConferm = false;
  ripete?: string;

  constructor() {}

  ngOnInit() {
  }

  vecchiaPassword(evt: string) {
    //console.log(evt);
  }

  changePassword(evt: string) {
    if (evt) {
      this.showConferm = true;
      this.cambioValore.emit('');
    } else {
      this.showConferm = false;
    }
  }

  confermaValore(evt: string) {
    if (this.password && this.password == this.ripete) {
      this.cambioValore.emit(this.password);
    } else {
      this.cambioValore.emit('');
    }
  }

}
