import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ble-icon',
  templateUrl: './ble-icon.component.html',
  styleUrls: ['./ble-icon.component.scss']
})
export class BleIconComponent implements OnInit {

  @Input() valore?: boolean;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() color = 'primary';
  @Input() width?: number = 250;
  @Input() height?: number = 250;
  @Input() prop: string;
  @Input() disabled?: boolean = false;

  @Output() cambioValore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.cambioValore.emit({name: this.prop, value: "clicked"});
  }

}
