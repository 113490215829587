import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, PlatformRef} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {APP_ROUTES} from './app.routes';
import {KeycloakService} from "keycloak-angular";
import {AuthGuard} from "./auth/auth.guard";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {SdgLibModule} from "sdg-lib";
import {Oauth2TokenInterceptor} from "./services/configMfeSenzaShell/oauth2-auth-token-interceptor.service";
import {InitAppService} from "./services/configMfeSenzaShell/initApp.service";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ristorazioneModule} from "./ristorazione/ristorazione.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  imports: [
    ristorazioneModule,
    SdgLibModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatTooltipModule,
    SdgLibModule,
    RouterModule.forRoot(APP_ROUTES)
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: KeycloakService,
      useFactory: keycloakServiceProvider,
      deps: ['keycloak-service']
    },
     {
       provide: APP_INITIALIZER,
       useFactory: initApp,
       multi: true,
       deps: [InitAppService]
     },
     { provide: HTTP_INTERCEPTORS, useClass: Oauth2TokenInterceptor, multi: true },
     AuthGuard
  ],
  bootstrap: [
      AppComponent
  ]
})


export class AppModule {
  constructor() {
  }

}

export function initApp(config: InitAppService) {
  return () => config.getJSONConfigurationPromise();
}

export function keycloakServiceProvider(keycloakService: KeycloakService): KeycloakService {
  console.log(keycloakService);
  return keycloakService;
}

