export class DescrizioneAttivita {

    id?: number;
    attivitaCod: string;
    attivitaDesc: string;


    constructor(data: any) {
        this.id = data.id;
        this.attivitaCod = data.attivitaCod;
        this.attivitaDesc = data.attivitaDesc;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.attivitaDesc ? this.attivitaDesc : '';
    }

    get value(): any {
        return this;
    }

}



