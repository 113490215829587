import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MsgService} from "../../../services/utils/msg.service";

@Component({
  selector: 'app-ble-input-textarea',
  templateUrl: './ble-input-textarea.component.html',
  styleUrls: ['./ble-input-textarea.component.scss']
})
export class BleInputTextareaComponent implements OnInit {

  initialValue = "";
  revertValue = "";

  @Input() valore: string | '';
  @Input() testo ?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() hidden ? = false;
  @Input() rows ? = 2;
  @Input() cols ? = 30;
  @Input() append? = true;
  @Input() msgForbidden?: string | '';

  @Output() cambioValore = new EventEmitter();

  constructor(private msgService: MsgService) { }

  ngOnInit(): void {
    if (this.valore != undefined) {
      let text = "";
      for (let valori of this.valore) {
        text += valori;
      }
      if (this.append) {
        this.initialValue = this.valore ? text : '';
        this.revertValue = this.valore ? text : ''
      }
    }
  }

  inputChange() {
    /*
    if (!this.valore.includes(this.initialValue)){
      this.msgService.msgAlert(this.msgForbidden!);
      this.valore = this.revertValue;
    } else {
      this.revertValue = this.valore;
    }
     */
    if (this.valore.includes(';')){
      this.msgService.msgAlert("Valore ; non ammesso");
      this.valore = this.revertValue;
    } else {
      this.revertValue = this.valore;
    }
    this.cambioValore.emit(this.valore);
  }
}
