export class TreeNodeMaterial {
  name: string;
  type: string;
  id?: any;
  children?: TreeNodeMaterial[];
  check?: boolean;
  indeterminate?: boolean;
  isExpanded?: boolean;

  constructor(data: any) {
    this.name = data.name;
    this.type = data.type;
    this.id = data.id ? data.id : "*";
    this.children = data.children ? data.children.map( (rc: TreeNodeMaterial) => new TreeNodeMaterial(rc)) : [];
    this.check = data.check;
    this.indeterminate = data.indeterminate;
    this.isExpanded = data.isExpanded;
  }
}
