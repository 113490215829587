import {TipoPasto} from "./TipoPasto";

export class TipoOrdine {

    id?: number;
    descrizione?: string;
    tipoPasto?: TipoPasto;
    orarioLimite?: string;
    flagTipo?: string;
    orarioConsegna?: string;
    orarioDa?: string;
    orarioA?: string;
    minutiScheduler?: number;
    giorniA?: number;


    constructor(data: any) {
        Object.assign(this, data);
        this.tipoPasto = data.tipoPasto ? new TipoPasto(data.tipoPasto) : undefined;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): TipoOrdine {
        return this;
    }

}



