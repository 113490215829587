
export class MailWelcome {
  password: string;
  userdata: string;
  username: string;
  mail: string;
  date: string;

  constructor(data: any) {
    this.password = data.password;
    this.userdata = data.userdata;
    this.username = data.username;
    this.mail = data.mail;
    this.date = data.date;
  }


  getOggCreate(): MailWelcomeCreate {
    return {
      password: this.password!,
      userdata: this.userdata!,
      username: this.username!,
      mail: this.mail!,
      date: this.date!
    };
  }

}

export interface MailWelcomeCreate {
  password: string;
  userdata: string;
  username: string;
  mail: string;
  date: string;

}
