
<div class="table-container" *ngIf="!noRowPattern && rowResize">
  <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
        <th mat-header-cell *matHeaderCellDef
                            [ngStyle]="{'width': column.size + '%'}">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>
        </th>

        <td mat-cell *matCellDef="let element"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">
            {{element}}
          <app-row-data [rowProp]="column.prop"
                        [rowData]="element"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowData($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

    </table>
  </div>
  <div *ngIf="showFooter" class="table-scroll">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

        <th mat-header-cell  *matHeaderCellDef
            [ngStyle]="{'width': column.size + '%' } ">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>

        </th>

        <td mat-cell *matCellDef="let element"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

          <app-row-data [rowProp]="column.prop"
                        [rowData]="element"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowData($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

    </table>
  </div>
  <ng-container *ngIf="!tableData?.length">
    <div class="msg-nessuno">
      {{nessunElemento}}
    </div>
  </ng-container>

  <div *ngIf="showFooter" class="table-footer">
    <mat-paginator #paginatorDefault
                   *ngIf="!totalPageslazy"
                   [pageSize]="rows"
                   [hidePageSize]="true"
                   showFirstLastButtons>
    </mat-paginator>

    <mat-paginator #paginatorLazy
                   *ngIf="totalPageslazy"
                   [pageSize]="rows"
                   [pageIndex]="pageIndex"
                   [length]="totalPageslazy"
                   [hidePageSize]="true"
                   (page)="cambioPage($event)"
                   showFirstLastButtons>
    </mat-paginator>
  </div>

</div>

<div class="table-container" *ngIf="!noRowPattern && !rowResize">
  <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
        <th mat-header-cell *matHeaderCellDef
            [ngStyle]="{'width': column.size + '%'}">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>
        </th>


        <td mat-cell *matCellDef="let element"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

          <app-row-data [rowProp]="column.prop"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [rowData]="element"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowData($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

    </table>
  </div>
  <div *ngIf="showFooter" class="table-scroll">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

        <th mat-header-cell  *matHeaderCellDef
            [ngStyle]="{'width': column.size + '%' } ">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>

        </th>

        <td mat-cell *matCellDef="let element"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

          <app-row-data [rowProp]="column.prop"
                        [rowData]="element"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowData($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

    </table>
  </div>
  <ng-container *ngIf="!tableData?.length">
    <div class="msg-nessuno">
      {{nessunElemento}}
    </div>
  </ng-container>

  <div *ngIf="showFooter" class="table-footer">
    <mat-paginator #paginatorDefault
                   *ngIf="!totalPageslazy"
                   [pageSize]="rows"
                   [hidePageSize]="true"
                   showFirstLastButtons>
    </mat-paginator>

    <mat-paginator #paginatorLazy
                   *ngIf="totalPageslazy"
                   [pageSize]="rows"
                   [pageIndex]="pageIndex"
                   [length]="totalPageslazy"
                   [hidePageSize]="true"
                   (page)="cambioPage($event)"
                   showFirstLastButtons>
    </mat-paginator>
  </div>

</div>

<div *ngIf="noRowPattern">
  <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
        <th mat-header-cell *matHeaderCellDef
            [ngStyle]="{'width': column.size + '%'}">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>
        </th>


        <td mat-cell *matCellDef="let element; let i = index"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

          <app-row-data [rowProp]="column.prop"
                        [rowData]="element"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowDataColor($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

    </table>
  </div>
  <div *ngIf="showFooter" class="table-scroll">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

        <th mat-header-cell  *matHeaderCellDef
            [ngStyle]="{'width': column.size + '%' } ">
          <app-row-header [rowProp]="column.prop"
                          [name]="column.name"
                          [order]="column.order"
                          [orderIcon]="orderColList[i]"
                          [filter]="true"
                          (callBackOrder)="changeOrder($event, i)">
          </app-row-header>

        </th>

        <td mat-cell *matCellDef="let element; let i = index"
            [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaViola': element.coloreRiga == 'viola',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso',
            'rigaBlu': element.coloreRiga == 'blu'
            }"
            [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

          <app-row-data [rowProp]="column.prop"
                        [rowData]="element"
                        [text0]="column.text0"
                        [text1]="column.text1"
                        [colTooltip]="column.tooltip || false"
                        [size]="column.size"
                        [html]="column.html || false"
                        (callBack)="callBackRowDataColor($event)">
          </app-row-data>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
    </table>
  </div>
  <ng-container *ngIf="!tableData?.length">
    <div class="msg-nessuno">
      {{nessunElemento}}
    </div>
  </ng-container>

  <div *ngIf="showFooter" class="table-footer">
    <mat-paginator #paginatorDefault
                   *ngIf="!totalPageslazy"
                   [pageSize]="rows"
                   [hidePageSize]="true"
                   showFirstLastButtons>
    </mat-paginator>

    <mat-paginator #paginatorLazy
                   *ngIf="totalPageslazy"
                   [pageSize]="rows"
                   [pageIndex]="pageIndex"
                   [length]="totalPageslazy"
                   [hidePageSize]="true"
                   (page)="cambioPage($event)"
                   showFirstLastButtons>
    </mat-paginator>
  </div>

</div>
