import {Paged} from "./Paged";
import {Struttura} from "./Struttura";
import {UnitaServizio} from "./UnitaServizio";
import {MyDate} from "./MyDate";
import {CausaliNC} from "./CausaliNC";
import {DettaglioNC} from "./DettaglioNC";
import {Ticket} from "./Ticket";

export class NCgenericaPaged extends Paged {
    content: NCgenerica[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map( (rc: NCgenerica) => new NCgenerica(rc) ) : [];
    }
}

export class NCgenerica {

    idTestataOrdine?: number;//
    struttura?: Struttura;//
    unitaServizio?: UnitaServizio;//
    idDettaglioOrdine?: number;//
    pazienteId?: number;//
    nominativo?: string;//
    cf?: string;//
    dataInsRic?: MyDate;//
    dataInsNC?: MyDate;
    dataValidazione?: MyDate;//
    note?: string;//
    causale?: CausaliNC;//
    listaDettNC?: DettaglioNC[];//
    id?: number;//
    numeroRichiesta?: number;//
    stato?: string; //
    statoValidazione?: string; //
    statoName?: string;
    tipoPasto?: string;
    dataPasto?: MyDate;
    tableShow?: string;
    accettaContesta?: boolean;
    descrizioneNC?: string;
    ticket?: Ticket;
    coloreRiga?: string;

    constructor(data: any) {
        Object.assign(this, data);
        this.accettaContesta = data.accettaContesta;
        this.statoName = data.stato == "P" ? "Provvisiorio" : "Definitivo";
        this.struttura = data.struttura ? new Struttura(data.struttura) : undefined;
        this.unitaServizio = data.unitaServizio ? new UnitaServizio(data.unitaServizio) : undefined;
        this.dataInsNC = data.dataInsNC ? new MyDate(data.dataInsNC) : undefined;
        this.dataValidazione = data.dataValidazione ? new MyDate(data.dataValidazione) : undefined;
        this.dataPasto = data.dataPasto ? new MyDate(data.dataPasto) : undefined;
        this.dataInsRic = data.dataInsRic ? new MyDate(data.dataInsRic) : undefined;
        this.causale = data.causale ? new CausaliNC(data.causale) : undefined;
        this.descrizioneNC = this.causale ? this.causale.descrizione ? this.causale.descrizione : this.causale.causaleDesc ? this.causale.causaleDesc : "" : "";
        this.listaDettNC = data.listaDettNC ? data.listaDettNC.map( (rc: DettaglioNC) => new DettaglioNC(rc) ) : [];
        this.coloreRiga = 'blu';
        this.tableShow = 'Non Conformità N°: '+this.id+' - Data Inserimento: ' + this.dataIns + ' - Note: ' + this.note + ' - Ordine N°: ' + this.idTestataOrdine
            + '<br><span class="sottoTesto">Causale: ' + this.descrizioneNC + '</span>';
      }

    get dataIns(): string {
        return this.dataInsNC? this.dataInsNC.giornoMeseAnno : '';
    }


 }



