import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormInputField} from '../../entities/FormInputField';
import {DetailsCallback} from '../../entities/DetailsCallback';
import {CallbackDetailsState} from '../../entities/enums/CallbackDetailsState';
import {PopUpDetails} from '../../entities/PopUpDetails';
import {TestiService} from '../../services/config/testi.service';

@Component({
  selector: 'app-pop-up-dettagli',
  templateUrl: './pop-up-dettagli.component.html',
  styleUrls: ['./pop-up-dettagli.component.scss']
})
export class PopUpDettagliComponent implements OnInit {

  title?: string;
  loading = false;
  arrayFormInfo: FormInputField[];
  objValue: any;
  dropdownList: Map<string, any[]> = new Map<string, any[]>();
  enableFooter = true;

  constructor(private testiService: TestiService, public dialogRef: MatDialogRef<PopUpDettagliComponent>, @Inject(MAT_DIALOG_DATA) public data: PopUpDetails) {
    this.title = this.data?.title ? this.data?.title : this.testiService.componentRistorazione.dettaglioTitle;
    this.arrayFormInfo = this.data.formArray ?? [];
    this.objValue = this.data.oggetto;
    this.enableFooter = !this.data.hiddenFooter;

    if (this.data.dropdownList) {
      this.dropdownList = this.data.dropdownList;
    }
  }

  ngOnInit(): void {


  }

  statoForm(event$: string) {
  }

  saveOgg(data: any) {
    this.dialogRef.close({type: CallbackDetailsState.SAVE, data } as DetailsCallback);
  }

  cancel() {
    this.dialogRef.close({type: CallbackDetailsState.CANCEL} as DetailsCallback );
  }

}
