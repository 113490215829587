import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ble-input-number',
  templateUrl: './ble-input-number.component.html',
  styleUrls: ['./ble-input-number.component.scss']
})
export class BleInputNumberComponent implements OnInit, OnChanges {

  @Input() valore?: number;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid? = false;
  @Input() unit?: string;
  @Input() keys?: boolean;
  @Input() min: number = 0;

  @Output() cambioValore = new EventEmitter();

  faPlus = faPlusCircle;
  faMinus = faMinusCircle;

  _valore?: number;

  constructor() { }

  ngOnInit(): void {
  }

  plus(){
    this._valore = this._valore ? this._valore + 1 : this.min + 1;
    this.inputChange();
  }

  minus(){
    this._valore = this._valore && this._valore > this.min ? this._valore - 1 : this.min;
    this.inputChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['valore'] && (this._valore = this.valore);
    this.inputChange()
  }

  inputChange() {
    this.cambioValore.emit(Number(this._valore));
  }


}
