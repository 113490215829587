import {MyDate} from './MyDate';
import {Paged} from "./Paged";
import {TipoOrdine} from "./TipoOrdine";
import {TipoPasto} from "./TipoPasto";
import {Struttura} from "./Struttura";
import {UnitaServizio} from "./UnitaServizio";
import {DettaglioOrdine} from "./DettaglioOrdine";

export class TestataOrdinePaged extends Paged {
  content: TestataOrdine[];

  constructor(data: any) {
    super(data);

    this.content = data.content ? data.content.map( (rc: TestataOrdine) => new TestataOrdine(rc) ) : [];
  }
}

export class TestataOrdine {
  id?: number;
  numero?: number;
  numOrdRif?: number;
  tipoOrd?: TipoOrdine;
  tipoPasto?: TipoPasto;
  struttura?: Struttura;
  unitaServizio?: UnitaServizio;
  stato?: string;
  dataPasto?: MyDate;
  dataOrdine?: MyDate;
  ordineCumulativo: boolean;
  numComplPastiCum?: number;
  numVitti?: number;
  numPazientiPresenti?: number;
  listaDettOrdine?: DettaglioOrdine[];
  coloreRiga?: string;
  tableShow?: string;
  rettificabile?: boolean;
  pastoPassato?: boolean;
  nonConformita?: boolean;
  statusNumber?: number;


  constructor(data: any) {
    this.id = data.id;
    this.numero = data.numero;
    this.numOrdRif = data.numOrdRif ? data.numOrdRif : undefined;
    this.tipoOrd = data.tipoOrd ? data.tipoOrd : undefined;
    this.tipoPasto = data.tipoPasto ? new TipoPasto(data.tipoPasto) : undefined;
    this.struttura = data.struttura ? new Struttura(data.struttura) : undefined;
    this.unitaServizio = data.unitaServizio ? new UnitaServizio(data.unitaServizio) : undefined;
    this.stato = data.stato;
    this.dataPasto = data.dataPasto ? new MyDate(data.dataPasto) : undefined;
    this.dataOrdine= new MyDate(new Date());
    this.ordineCumulativo = data.ordineCumulativo ? data.ordineCumulativo : false;
    this.numComplPastiCum = data.numComplPastiCum ? data.numComplPastiCum : undefined;
    this.numVitti = data.numVitti;
    this.numPazientiPresenti = data.numPazientiPresenti ? data.numPazientiPresenti : undefined;
    this.coloreRiga = data.stato == 'Provvisorio' ? 'verde' :
        data.stato == 'Inviato' || data.stato == 'Consegnato' ? 'blu' :
        data.stato == 'Validato'  ? 'viola' :
        data.stato == 'Annullato' || data.stato == 'Rettificato' ? 'rosso' : undefined;
    this.statusNumber = data.stato == 'Provvisorio' ? 0 :
                        data.stato == 'Inviato' ? 1 :
                        data.stato == 'Consegnato' ? 2 :
                        data.stato == 'Validato'  ? 3 :
                        data.stato == 'Annullato' ? 4 :
                        data.stato == 'Rettificato' ? 5 : undefined;
    this.tableShow = 'Ordine N°: '+this.numero+' - Data Pasto: ' + this.dataPastoShow + ' - Stato: ' + this.stato + ' - Pasto: ' + this.tipoPastoName
                      + '<br><span class="sottoTesto">Tipo Ordine: ' + this.tipoOrdName + '</span>';
    this.listaDettOrdine = data.listaDettOrdine ? data.listaDettOrdine.map( (dett: DettaglioOrdine) => new DettaglioOrdine(dett)) : undefined
    this.rettificabile = data.rettificabile ? data.rettificabile : false;
    this.nonConformita = data.nonConformita ? data.nonConformita : false;
    this.pastoPassato = this.dataPasto && this.dataPasto.getTime() < new MyDate(new Date().setHours(0, 0, 0, 0)).getTime() ? true : false;
    console.log(this.dataPasto ? this.dataPasto.getTime() + ' ' + new MyDate(new Date().setHours(0, 0, 0, 0)).getTime() : 'ok')
  }

  get tipoOrdName(): string {
    return this.tipoOrd ? this.tipoOrd.descrizione! : '';
  }
  get tipoPastoName(): string {
    return this.tipoPasto ? this.tipoPasto.descrizione! : '';
  }
  get strutturaName(): string {
    return this.struttura ? this.struttura.descrizione! : '';
  }
  get unitaServizioName(): string {
    return this.unitaServizio ? this.unitaServizio.usDesc! : '';
  }
  get cumulativo(): string {
    return this.ordineCumulativo ? String.fromCharCode(0x2714) : "";
  }

  get dataPastoShow(): string {
    return this.dataPasto ? this.dataPasto.giornoMeseAnno : '';
  }

  get dataPastoSave(): string {
    return this.dataPasto ? this.dataPasto.giornoMeseAnnoOreSec : '';
  }

  get dataOrdineSave(): string {
    return this.dataOrdine ? this.dataOrdine.giornoMeseAnnoOreSec : '';
  }

  getOggCreate(): TestataOrdineCreate {
    return {
      id: this.id!,
      numero: this.numero!,
      tipoOrd: new TipoOrdine(this.tipoOrd),
      tipoPasto: new TipoPasto(this.tipoPasto),
      struttura: new Struttura(this.struttura),
      unitaServizio: new UnitaServizio(this.unitaServizio) ,
      dataPasto: this.dataPasto!.dateSave,
      stato: this.stato!,
      numComplPastiCum: 0,
      numVitti: 0,
      numPazientiPresenti: 0
    }
  }
}

export interface TestataOrdineCreate {
  id: number;
  numero: number;
  tipoOrd: TipoOrdine;
  tipoPasto: TipoPasto;
  struttura: Struttura;
  unitaServizio: UnitaServizio;
  stato: string;
  dataPasto: string;
  numComplPastiCum: number;
  numVitti: number;
  numPazientiPresenti: number;
}
