import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {TestiService} from '../../../../services/config/testi.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-dettaglio-dragdrop',
  templateUrl: './dettaglio-dragdrop.component.html',
  styleUrls: ['./dettaglio-dragdrop.component.scss']
})
export class DettaglioDragdropComponent implements OnInit {

  @Input() listaDaAssegnare: any[] = [];
  @Input() listaAssegnati: any[] = [];
  @Input() disable = false;
  @Input() DragDropMaxHeight? = 10000;

  @Output() updateListaDispositivi = new EventEmitter<any[]>();

  @Output() cambioValore = new EventEmitter<any[]>();

  testi: any;
  loading = false;

  constructor(private testiService: TestiService) {
    this.testi = testiService.componentRistorazione;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.updateLists();
    })
  }

  /**
   * move item inside dragdrop lists
   */
  drop(event: CdkDragDrop<string[]>, type: 'listaDaAssegnare' | 'listaAssegnati') {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
      );
    }
    this.updateLists(event.container.data, type);
  }


  /**
   * emit new lists
   */
  private updateLists(dataMoved?: string[], type?: 'listaDaAssegnare' | 'listaAssegnati') {
      //this.cambioValore.emit(this.listaDaAssegnare)
      this.cambioValore.emit(this.listaAssegnati)
  }

}
