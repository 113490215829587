import {Paged} from "./Paged";
import {CentroCottura} from "./CentroCottura";
import {MyDate} from "./MyDate";
import {TipoStruttura} from "./TipoStruttura";

export class Struttura {

    id?: number;
    descrizione?: string;
    tipoStruttura?: TipoStruttura;
    centroCottura?: CentroCottura;
    oraConsegnaVittoDA?: MyDate;
    oraConsegnaVittoA?: MyDate;
    constructor(data: any) {
        Object.assign(this, data);
        this.tipoStruttura = data.tipoStruttura ? new TipoStruttura(data.tipoStruttura) : undefined;
        this.centroCottura = data.centroCottura ? new CentroCottura(data.centroCottura) : undefined;
        this.oraConsegnaVittoDA = data.oraConsegnaVittoDA ? new MyDate(data.oraConsegnaVittoDA) : undefined;
        this.oraConsegnaVittoA = data.oraConsegnaVittoA ? new MyDate(data.oraConsegnaVittoA) : undefined;
        // this.tipoStruttura = data.tipoStruttura;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): Struttura {
        return this;
    }

    get destipoStruttura() {
        return this.tipoStruttura ? this.tipoStruttura.viewValue : '';
    }

    get descentroCottura() {
        return this.centroCottura ? this.centroCottura.viewValue : '';
    }


    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            tipoStruttura: this.tipoStruttura,
            centroCottura: this.centroCottura,
            oraConsegnaVittoDA: this.oraConsegnaVittoDA,
            oraConsegnaVittoA: this.oraConsegnaVittoA,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class StrutturaPaged extends Paged {
    content: Struttura[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Struttura(ts)) : [];
    }
}

