import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableCallback} from '../../../entities/TableCallback';
import {CallbackTableState} from '../../../entities/enums/CallbackTableState';
import {TestiService} from '../../../services/config/testi.service';
import {Observable} from 'rxjs';
import {FilterType} from '../../../entities/utils/FilterType';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Input() tableType?: string;
  @Input() filterHeader?: string;
  @Input() buttonAdd?: boolean;
  @Input() disableAdd?: boolean = false;
  @Input() buttonAddName?: string;
  @Input() cleanName?: boolean;
  @Input() buttonFunc? = false;
  @Input() buttonFuncName?: string;
  @Input() buttonAggiorna = false;
  @Input() filterHeaderList?: FilterType[] = [];
  @Input() filterDataList?: Map<string, any[]> | undefined;
  @Input() centerTitle?: number = 4;

  @Output() callBack = new EventEmitter<TableCallback>();
  @Output() setFilter = new EventEmitter();

  callbackTableState = CallbackTableState;

  filterTypeList: FilterType[] = [];
  allList: Map<string, any[]> = new Map<string, any[]>();
  testi;

  constructor(
      private testiService: TestiService
              ) {
    this.testi = this.testiService.componentRistorazione;
  }

  ngOnInit(): void {
    this.setFilterType(this.filterHeader);
    this.setAllList(this.filterHeader);
  }


  eventHeaders(event: CallbackTableState) {
    this.callBack.emit({type: event});
  }


  private setFilterType(type: string | undefined): void {

    switch (type) {
      case 'standard':
        this.setFilterStandard();
        break;
      case 'pulizie':
        this.setFilterpulizie();
        break;
      case 'stanze':
        this.setFilterStanze();
        break;
      default:
        break;
    }


  }


  private setFilterStandard(): void {
    this.filterTypeList = [
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterpulizie(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stato + ':',  type: 'dropdown', max: '', param: 'idStato',  prop: 'idStatoTicket', lista: 'statoTicket' },
      {label: this.testi.tipoServizio + ':',  type: 'dropdown', max: '', param: 'idServizio', prop: 'idTipoInterventi', lista: 'tipoIntervento'  },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio' },
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterStanze(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stanza + ':',  type: 'dropdown', max: '', param: 'idStanza', prop: 'id', lista: 'stanze'  },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio'},
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }



private setAllList(type: string |undefined) {
    const arrayName: Map<string, Observable<any[]>>  = new Map<string, Observable<any[]>>();

    this.getallList(arrayName);

  }


  private getallList(arrayName: Map<string, Observable<any[]>>) {
    arrayName.forEach( (value: Observable<any[]>, key: string) => this.allList.set(key, []));

    arrayName.forEach( (value: Observable<any[]>, key: string) => {
      value.subscribe( res => {
        this.allList.set(key, res)
      });
    });
  }

  print() {

  }
}
