import {Paged} from "./Paged";
import {TipoPrenotazione} from "./TipoPrenotazione";

export class TipoPasto {

    id?: number;
    descrizione?: string;
    tipoPrenotazione?: TipoPrenotazione;

    constructor(data: any) {
        Object.assign(this, data);
        this.tipoPrenotazione = data.tipoPrenotazione ? new TipoPrenotazione(data.tipoPrenotazione) : undefined;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): TipoPasto {
        return this;
    }

    get desTipoPrenotazione() {
        return this.tipoPrenotazione ? this.tipoPrenotazione.viewValue : '';
    }



    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            tipoPrenotazione: this.tipoPrenotazione,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



}

export class TipoPastoPaged extends Paged {
    content: TipoPasto[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new TipoPasto(ts)) : [];
    }
}

