import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthModule} from "../auth/auth.module";
import {TableViewModule} from "../shared-modules/table-view/table-view.module";
import {FormFieldModule} from "../shared-modules/form-field/form-field.module";
import {HeaderPopupModule} from "../shared-modules/header-popup/header-popup.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {PopupConfermaComponent} from "../shared-modules/popup-conferma/popup-conferma/popup-conferma.component";
import {PopUpDettagliComponent} from "../shared-modules/pop-up-dettagli/pop-up-dettagli.component";
import {SnackBarComponent} from "../shared-modules/snack-bar/snack-bar.component";
import {ristorazioneRouting} from "./ristorazione.routes";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatRadioModule} from "@angular/material/radio";


@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    TableViewModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTooltipModule,
    HeaderPopupModule,
    FormFieldModule,
    ristorazioneRouting
  ],
  declarations: [
    PopupConfermaComponent,
    SnackBarComponent,
    PopUpDettagliComponent
  ],
  exports: [
    SnackBarComponent,
    TableViewModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTooltipModule,
    HeaderPopupModule,
    FormFieldModule
  ]


})
export class ristorazioneModule { }

/*
@NgModule({})
export class RistorazioneSharedModule {
  static forRoot(): ModuleWithProviders<ristorazioneModule> {
    return {
      ngModule: ristorazioneModule,
      providers: []
    }
  }
}
*/
