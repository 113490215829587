import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ApiRestKeycloakService} from "./api-rest-keycloak.service";
import {Utente} from "../entities/Utente";
import {ApiRestService} from "./api-rest.service";
import {HttpHeaders} from "@angular/common/http";
import {Role} from "../entities/Role";
import {Credentials} from "../entities/Credentials";
import {MailService} from "./mail.service";
import {MsgService} from "./utils/msg.service";
import {FormControl} from "@angular/forms";
import {TestiService} from "./config/testi.service";

function check_if_valid(value: string, utenti: Utente[], type: string, update?:number){
    let flag = true;
    if(value){
        let exist = false;
        if (type == "username") {
            for (let utente of utenti){ if (value == utente.username) {exist = true;}}
            if (exist) {flag = false;}
        }
        if (type == "email") {
            for (let utente of utenti){ if (value == utente.email) { exist = true;}
            }
            if (exist) { flag = false }
            if (update! < 2){ flag = true }
        }
    }
    return flag ? null : {
        valid: {
            valid: false
        }
    };
}


@Injectable({
  providedIn: 'root'
})
export class UtentiService {

    utenti: Utente[] | undefined;

    testi: any;

    utente: string;

    update = 2;

    constructor(private apiKeycloakRestService: ApiRestKeycloakService,
                private mailService: MailService,
                private apiRestService: ApiRestService,
                private msgService: MsgService,
                private testiService: TestiService){
        this.testi = this.testiService.componentRistorazione;
    }

    public userExists = (control:FormControl) => {
        return check_if_valid(control.value, this.utenti!, "username");
    }

    public mailExists = (control:FormControl) => {
        const update = this.update;
        this.update = this.update + 1;
        return check_if_valid(control.value, this.utenti!, "email", update);
    }

    private getAllUsers(){
        this.getAllUtenti().subscribe( (res: Utente[] | undefined) => {
            this.utenti = res;
        });
    }

    getAllUtenti(): Observable<Utente[]> {
        const url = '/users';
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((utente: any) => new Utente(utente)))
            );
    }

    setUpdate() {
        this.update = 0;
    }


    getUtente(): string {
        return this.utente;
    }

    setUtenti(utenti: Utente[]) {
        this.utenti = utenti;
    }

    validateBeforeSave(data: Utente): number {
        let chkUser = true;
        let chkMail = true;
        if (data.id == undefined){
            this.utenti!.forEach((user) => {
                if (user.username == data.username) {
                    chkUser = false;
                }
                if (user.email == data.email) {
                    chkMail = false;
                }
            });
        } else {
            this.utenti!.forEach((user) => {
                if (user.email == data.email) {
                    chkMail = false;
                }
            })
        }

        if (chkUser && chkMail) {
            return 0;
        }
        if (!chkUser && !chkMail) {
            this.msgService.msgStringError( this.testi.errUserMailExist);
            return 3;
        }
        if (chkUser && !chkMail) {
            this.msgService.msgStringError(this.testi.errMailExist);
            return 1;
        }
        if (!chkUser && chkMail) {
            return 2;
            this.msgService.msgStringError(this.testi.errUserExist);
        }
        return 0;
    }


    createUserWithRoles(utente: Utente): Observable<any> {
        return this.createUtente(utente).pipe(
            switchMap(userCreate => {
                return this.getUtenteByUsername(utente).pipe(
                    switchMap(data => {
                        return forkJoin(
                            this.setUserRoles(data[0].id, utente),
                            this.setUserPassword(data[0].id, utente)
                        );
                    })
                )
            }))
    }

    getUserByMail(mail: string){
        let url = '/users?email='+ mail;
        return this.apiKeycloakRestService.getWithHeader(url);
    }

    createUtente(utente: Utente): Observable<any> {
        const url = `/users`;
        const ut = utente.getOggCreate();
        return this.apiKeycloakRestService.postWithHeaderAndBody(url, utente.getOggCreate());
    }

    setUserRoles(idUtente: string, utente: Utente): Observable<any> {
        let url = '/users/' + idUtente + '/role-mappings/realm';
        return this.apiKeycloakRestService.postWithHeaderAndBody(url, utente.roles);
    }

    generatePassword(): string {
        const minLength = 8;
        const capitalLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const smallLetter = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

        let password = '';

        // Ensure at least one character from each category
        password += capitalLetter[Math.floor(Math.random() * capitalLetter.length)];
        password += smallLetter[Math.floor(Math.random() * smallLetter.length)];
        password += numbers[Math.floor(Math.random() * numbers.length)];
        password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        // Fill the rest of the password
        const remainingLength = minLength - password.length;
        const allCharacters = capitalLetter + smallLetter + numbers + specialCharacters;
        for (let i = 0; i < remainingLength; i++) {
            password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
        }

        // Shuffle the password characters
        password = this.shuffle(password);

        return password;
    }

    shuffle(input: string): string {
        const array = input.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    setUserPassword(idUtente: string, utente: Utente): Observable<any> {
        let url = '/users/' + idUtente + '/reset-password';
        const password = this.generatePassword();
        //console.log("Password:", password)
        return forkJoin([
            this.mailService.sendWelcomeMail(utente, password),
            this.apiKeycloakRestService.putWithHeaderAndBody(url, new Credentials({"value":password}))
            ]);
    }

    getAllRuoli(header: HttpHeaders): Observable<Role[]> {
        const url = `/roles`;
        return this.apiKeycloakRestService.getWithHeader(url, header)
            .pipe(
                map(res => res.map((ruoli: any) => new Role(ruoli))))
    }

    getUserRoles(header: HttpHeaders, id: string): Observable<Role[]> {
        const url = '/users/' + id + '/role-mappings/realm';
        return this.apiKeycloakRestService.getWithHeader(url, header)
            .pipe(
                map(res => res.map((ruoli: any) => new Role(ruoli)))
            )
    }

    getUtenteByUsername(utente: Utente): Observable<Utente[]> {
        const url = `/users?username=` + utente.username + "&exact=true";
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((utente: Utente) => new Utente(utente)
                ))
            );
    }


}
