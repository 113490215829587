
export class Piatto{

    id?: number;
    descrizione?: string;
    idTipoPiatto?:number;
    idDieta?: number;

    constructor(data: any) {
        this.id = data.id ? data.id : 0;
        this.descrizione = data.descrizione;
        this.idTipoPiatto = data.idTipoPiatto ? data.idTipoPiatto : 0;
        this.idDieta = data.idDieta ? data.idDieta : 0;
    }

    nomePiatto(): string{
        return this.descrizione ? this.descrizione : 'noDesc';
    }


    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }

    get value(): Piatto {
        return this;
    }

}

