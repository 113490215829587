import {concatAll, map, toArray} from 'rxjs/operators';
import {concat, merge, Observable} from 'rxjs';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Workbook, Worksheet} from 'exceljs';
import * as fs from 'file-saver';
import {DetailsCallback} from '../../entities/DetailsCallback';
import {CallbackDetailsState} from '../../entities/enums/CallbackDetailsState';
import {PopUpDetails} from '../../entities/PopUpDetails';
import {DropdownItem} from '../../entities/DropdownItem';


export abstract class SharedFunction {

  static multiSelectListSingle(data: any[], propNome: string, propValue: string): DropdownItem[] {
    const arr: DropdownItem[] = [];
    data.forEach( item => arr.push({viewValue: item[propNome], value: item[propValue] }) );
    return arr;
  }

  static multiSelectListOgg(data: any[], propNome: string): DropdownItem[] {
    const arr: DropdownItem[] = [];
    data.forEach( item => arr.push({viewValue: item[propNome], value: item }) );
    return arr;
  }

  static orderByAsc(array: any[], prop: string): any[] {
    return array.sort((a, b) => {
      const nameA = typeof a[prop] === 'string' ? a[prop].toUpperCase() : a[prop]; // ignore upper and lowercase
      const nameB = typeof b[prop] === 'string' ? b[prop].toUpperCase() : b[prop]; // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  static orderByDesc(array: any[], prop: string): any[] {
    return array.sort((b, a) => {
      const nameA = typeof a[prop] === 'string' ? a[prop].toUpperCase() : a[prop]; // ignore upper and lowercase
      const nameB = typeof b[prop] === 'string' ? b[prop].toUpperCase() : b[prop]; // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  static removeDuplicate(array: any[], prop: string): any[] {
    const _arr: any[] = [];
    array.forEach( item => {
      if ( !_arr.find( _item => _item[prop] === item[prop] ) ) {
        _arr.push(item);
      }
    });
    return _arr;
  }

  static groupBy(array: any[], prop: string): any[] {
    return array.reduce((r, a) => {
      r[a[prop]] = [...r[a[prop]] || [], a];
      return r;
    }, {});
  }

  static openPopUp(dialogComp: MatDialog, data: PopUpDetails, component: any, dialog?: MatDialogConfig): Observable<DetailsCallback> {
    const _dialog: MatDialogConfig = dialog ? dialog : { maxHeight: '80%',  minHeight: '60%', maxWidth: '900px', disableClose: true, autoFocus: false };
    _dialog.data = data;

    const dialogRef = dialogComp.open(component, _dialog);

    const closeClick$ = dialogRef.backdropClick();
    const closePop$ = dialogRef.afterClosed();

    return merge(closeClick$, closePop$)
      .pipe( map((res: any) => res ? res : {type: CallbackDetailsState.CANCEL } ) );
  }

  static getConfigWidePopUp(isMobile: boolean): MatDialogConfig {
    const width = isMobile ? '100%' : '80%';

    return  {
      maxHeight:  window.innerHeight + 'px', //'80%',
      minHeight: '60%',
      width,
      disableClose: true,
      autoFocus: false
    };
  }

  static getConfigBigPopUp(isMobile: boolean): MatDialogConfig {
    const width = isMobile ? '100%' : '80%';

    return  {
      maxHeight:  window.innerHeight + 'px', //'80%',
      minHeight: '70%',
      height: '70%',
      width,
      disableClose: true,
      autoFocus: false
    };
  }

  static getConfigPopUp(isMobile: boolean): MatDialogConfig {
    const width = isMobile ? '100%' : '80%';

    return  {
      maxHeight:  window.innerHeight + 'px', //'80%',
      minHeight: '60%',
      width,
      maxWidth: '900px',
      disableClose: true,
      autoFocus: false
    };
  }

  static openPopUpConferma(dialogComp: MatDialog, data: any, component: any, dialog?: MatDialogConfig): MatDialogRef<any> {
    const _dialog: MatDialogConfig = dialog ? dialog : { maxHeight: '80%', maxWidth: '500px' , data, autoFocus: false, disableClose: false, role: 'alertdialog' };
    const dialogRef = dialogComp.open(component, _dialog);

    dialogRef.afterClosed()
      .pipe(
        map(res => res ? res : {type: CallbackDetailsState.CANCEL } ),
      );

    return dialogRef;
  }

  static getByArrayObservable(array$: Observable<any>[]): Observable<any> {
    return concat(array$)
      .pipe(
        concatAll(),
        toArray()
      );
  }

  static objectCompare( x: any, y: any ): boolean {
    if ( x === y ) { return true; }
    // if both x and y are null or undefined and exactly the same

    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) { return false; }
    // if they are not strictly equal, they both need to be Objects

    if ( x.constructor !== y.constructor ) { return false; }
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for ( const p in x ) {
      if ( ! x.hasOwnProperty( p ) ) { continue; }
      // other properties were tested using x.constructor === y.constructor

      if ( ! y.hasOwnProperty( p ) ) { return false; }
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if ( x[ p ] === y[ p ] ) { continue; }
      // if they have the same strict value or identity then they are equal

      if ( typeof( x[ p ] ) !== 'object' ) { return false; }
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if ( ! this.objectCompare( x[ p ],  y[ p ] ) ) { return false; }
      // Objects and Arrays must be tested recursively
    }

    for ( const p in y ) {
      if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) {
        return false;
    }
      }
    // allows x[ p ] to be set to undefined

    return true;
  }

  /*  ------------- Export Excel --------------- */
  static exportAsExcelFile(data: any , excelFileName: string, title?: string, image64?: any): void {
    const workbook = new Workbook();

    // let worksheet: Worksheet = workbook.addWorksheet(title != null ? title : 'No Name', {properties: {defaultColWidth: 25}});

    for (const prop in data) {
      const worksheet: Worksheet = workbook.addWorksheet(prop != null ? prop : 'No Name', {properties: {defaultColWidth: 25}});
      this.excelMakeWorksheet(worksheet, title!, prop, data[prop]);

      if (image64) {
        this.excelAddImage(workbook, worksheet, image64);
      }
    }

    workbook.xlsx.writeBuffer().then((data1) => {
      const blob = new Blob([data1], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName + '.xlsx');
    });
  }

  static excelMakeWorksheet(worksheet: Worksheet, title: string, titleWorksheet: string, data: any[]) {
    // Title
    const titleFile = worksheet.addRow([title]);

    // Subtitle
    const titleSheet = worksheet.addRow([titleWorksheet]);

    // Set font, size and style in title row.
    titleFile.font = { name: 'Times New Roman', family: 4, size: 16, underline: 'single', bold: true };
    titleSheet.font = { name: 'Times New Roman', family: 4, size: 16, underline: 'single', bold: true };

    // Blank Row
    worksheet.addRow([]);

    const header = Object.keys(data[0]);
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thick' }, left: { style: 'thick' }, bottom: { style: 'thick' }, right: { style: 'thick' } };
      cell.font = {bold: true, size: 14};
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    data.forEach(i => {
      const thisRow = worksheet.addRow(Object.values(i).map(k => k = typeof k == 'string' ? k.trim() : k));
      thisRow.eachCell({ includeEmpty: true }, (cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
      });
    });

    //gestione larghezza colonne in base al contenuto
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column.eachCell!({ includeEmpty: false }, (cell, rowNum) => {
        if (rowNum > 1) {
          maxLength = Math.max(maxLength, 10, cell.value ? cell.value.toString().length : 0);
        }
      });
      column.width = maxLength + 10;
    });
  }

  static excelAddImage(workbook: Workbook, worksheet: Worksheet, image64: any): Worksheet {
    const imageChart = workbook.addImage({
      base64: image64,
      extension: 'png',
    });

    worksheet.addImage(imageChart, {
      tl: { col: 1, row: 1 },
      ext: { width: 300, height: 300 }
    });

    return worksheet;
  }

  /*  ------------- Fine Export Excel --------------- */

}
