import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {MyDate} from '../../../entities/MyDate';
import {DatepickerHeaderComponent} from './datepicker-header/datepicker-header.component';
import {MatDatepicker} from '@angular/material/datepicker';

@Component({
  selector: 'app-ble-datepicker',
  templateUrl: './ble-datepicker.component.html',
  styleUrls: ['./ble-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BleDatepickerComponent implements OnInit {

  @Input() valore?: Date;
  @Input() testo?: string;
  @Input() max?: Date;
  @Input() min?: Date;
  @Input() block?: boolean;
  @Input() hidden ? = false;
  @Input() invalid ? = false;
  @Input() clear ? = false;
  @Input() full ? = true;
  @Input() type: 'month' | 'year' | 'multi-year' = 'month';

  @Output() cambioValore = new EventEmitter();

  dataPickerHeader = DatepickerHeaderComponent;

  nomeMese?: Date;

  constructor() {
  }

  ngOnInit() {
    if (this.type == 'year') {
      this.nomeMese = this.valore;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getMonth(event: any, picker: MatDatepicker<any>) {
    if (this.type == 'year') {
      this.nomeMese = event;
      picker.close();
      this.cambioValore.emit({type: 'filterData', mese: event.month() + 1, anno: event.year() });
    }

  }

  clearStartDate() {
    this.valueChange(undefined);
  }

  valueChange(evt: Date|undefined) {
    this.valore = evt;
    evt && this.cambioValore.emit(new MyDate(evt));
    !evt && this.cambioValore.emit('');
  }

}
