import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MyDate} from '../../../entities/MyDate';

@Component({
  selector: 'app-row-header',
  templateUrl: './row-header.component.html',
  styleUrls: ['./row-header.component.scss']
})
export class RowHeaderComponent implements OnInit, OnDestroy {

  @Input() rowProp: any;
  @Input() filter?: boolean;
  @Input() filterList: any[] = [];
  @Input() order?: string;
  @Input() orderIcon?: string;
  @Input() name?: string;

  @Output() callBack = new EventEmitter();
  @Output() callBackOrder = new EventEmitter();

  inputField$ = new Subject<string>();

  constructor() {}

  ngOnInit() {
    //console.log(this.orderIcon);
    this.inputField$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
      .subscribe(value => {
        this.inputFilter(value);
      });
  }

  changeOrder(order: string | undefined) {
    if (order) {
      this.callBackOrder.emit(order);
    }
  }

  inputFilter(event: any) {
    this.callBack.emit({type: 'text', name: event.target.name, data: event.target.value});
  }

  inputNumber(event: any) {
    this.callBack.emit({type: 'number', name: event.target.name, data: Number(event.target.value)});
  }

  filterData(name: any, event: MyDate) {
    this.callBack.emit({type: 'data', name, data: event });
  }

  dropdown(event: any) {
    const data = event.value ? event : null;
    this.callBack.emit({type: 'dropwdown', name: this.rowProp, data: data});
  }

  ngOnDestroy() {
    this.inputField$.unsubscribe();
  }
}
