import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ApiRestKeycloakService} from "./api-rest-keycloak.service";
import {ApiRestService} from "./api-rest.service";
import {HttpHeaders} from "@angular/common/http";
import {MailService} from "./mail.service";
import {MsgService} from "./utils/msg.service";
import {TestiService} from "./config/testi.service";
import {PuliziePaged} from "../entities/Pulizie";
import {CausaliNC} from "../entities/CausaliNC";
import {TreeNodeMaterial} from "../entities/TreeNodeMaterial";
import {TipoStruttura} from "../entities/TipoStruttura";
import {Struttura} from "../entities/Struttura";
import {UnitaServizio} from "../entities/UnitaServizio";
import {TipoPasto} from "../entities/TipoPasto";
import {TipoOrdine} from "../entities/TipoOrdine";
import {TestataOrdine, TestataOrdinePaged} from "../entities/TestataOrdine";
import {Pazienti} from "../entities/Pazienti";
import {Dieta} from "../entities/Dieta";
import {TipoMenu} from "../entities/TipoMenu";
import {DettaglioOrdine} from "../entities/DettaglioOrdine";
import {Piatti} from "../entities/Piatti";
import {StatoOrdine} from "../entities/StatoOrdine";
import {Ordine} from "../entities/Ordine";
import {Piatto} from "../entities/Piatto";
import {NCgenerica, NCgenericaPaged} from "../entities/NCgenerica";


@Injectable({
    providedIn: 'root'
})
export class RistorazioneAppService {

    testi: any;

    utente: string;

    lastActivity: Date;

    ricercaPaziente: boolean = true;
    ricercaNC: boolean = true;
    ricercaOrdine: boolean = true;
    ricercaNuovaNC: boolean = true;

    loading: boolean=false;

    history: any[] = [];

    selectedTree: TreeNodeMaterial[];

    ordine: TestataOrdine;
    ordineNNC: TestataOrdine;
    modeNC: boolean;
    ncGenerica: NCgenerica;
    searchOrdine: TestataOrdine;
    paziente: DettaglioOrdine;
    ncspecifica: DettaglioOrdine;
    tipoStrutturaId: number | null;
    unitaServizioId: number | null;
    strutturaId: number | null;
    tipoStrutturaIdNNC: number | null;
    unitaServizioIdNNC: number | null;
    strutturaIdNNC: number | null;
    tipoStrutturaIdPazienti: number | null;
    unitaServizioIdPazienti: number | null;
    strutturaIdPazienti: number | null;
    ordini: TestataOrdinePaged | undefined;
    ordiniNNC: TestataOrdinePaged | undefined;
    service: string | null;
    ordiniPazienti: TestataOrdinePaged | undefined;
    searchType: string | undefined;
    oggetto: Ordine | undefined;
    oggettoNNC: Ordine | undefined;
    oggettoPazienti: Ordine | undefined;
    searchStringParams: [string,number] | ["",0];
    searchStringParamsNNC: [string,number] | ["",0];
    searchStringParamsNC: [string,number] | ["",0];
    searchStringParamsPazienti: [string,number] | ["",0];
    dropdownList: Map<string, any[]> = new Map<string, any[]>();
    dropdownListNNC: Map<string, any[]> = new Map<string, any[]>();
    dropdownListPazienti: Map<string, any[]> = new Map<string, any[]>();

    update = 2;

    constructor(private apiKeycloakRestService: ApiRestKeycloakService,
                private mailService: MailService,
                private apiRestService: ApiRestService,
                private msgService: MsgService,
                private testiService: TestiService){
        this.testi = this.testiService.componentRistorazione;
    }

    setLoading(data: boolean){
        console.log("Set Loading: ", data);
        this.loading = data;
    }

    getLoading(): boolean {
        return this.loading;
    }

    setRicercaNC(data: boolean){
        this.ricercaNC = data;
    }

    getRicercaNC(): boolean {
        return this.ricercaNC;
    }

    setRicercaPaziente(data: boolean){
        this.ricercaPaziente = data;
    }

    getRicercaPaziente(): boolean {
        return this.ricercaPaziente;
    }

    setRicercaOrdine(data: boolean){
        this.ricercaOrdine = data;
    }

    getRicercaOrdine(): boolean {
        return this.ricercaOrdine;
    }

    setRicercaNuovaNC(data: boolean){
        this.ricercaNuovaNC = data;
    }

    getRicercaNuovaNC(): boolean {
        return this.ricercaNuovaNC;
    }

    setLastActivity(date: Date){
        this.lastActivity = new Date(date);
    }

    getLastActivity(): Date {
        return this.lastActivity;
    }


    setHistory(data: any[]){
        this.history = data;
    }
    getHistory(): any[] {
        return this.history;
    }

    setService(data: string | null){
        this.service = data;
    }
    getService(): string | null{
        return this.service;
    }

    setStrutturaId(data:number | null) {
        this.strutturaId=data;
    }
    getStrutturaId(): number | null{
        return this.strutturaId;
    }

    setStrutturaIdNNC(data:number | null) {
        this.strutturaIdNNC=data;
    }
    getStrutturaIdNNC(): number | null{
        return this.strutturaIdNNC;
    }

    setStrutturaIdPazienti(data:number | null) {
        this.strutturaIdPazienti=data;
    }
    getStrutturaIdPazienti(): number | null{
        return this.strutturaIdPazienti;
    }

    setTipoStrutturaId(data: number | null){
        this.tipoStrutturaId=data;
    }
    getTipoStrutturaId(): number | null {
        return this.tipoStrutturaId;
    }

    setTipoStrutturaIdNNC(data: number | null){
        this.tipoStrutturaIdNNC=data;
    }
    getTipoStrutturaIdNNC(): number | null {
        return this.tipoStrutturaIdNNC;
    }

    setTipoStrutturaIdPazienti(data: number | null){
        this.tipoStrutturaIdPazienti=data;
    }
    getTipoStrutturaIdPazienti(): number | null {
        return this.tipoStrutturaIdPazienti;
    }

    setUnitaServizioId(data: number | null){
        this.unitaServizioId=data;
    }

    getUnitaServizioId(): number | null {
        return this.unitaServizioId;
    }

    setUnitaServizioIdPazienti(data: number | null){
        this.unitaServizioIdPazienti=data;
    }

    getUnitaServizioIdPazienti(): number | null {
        return this.unitaServizioIdPazienti;
    }

    setDropDown(data: any) {
        this.dropdownList = data;
    }
    getDropDown(): Map<string,any[]>{
        return this.dropdownList;
    }

    setDropDownNNC(data: any) {
        this.dropdownListNNC = data;
    }
    getDropDownNNC(): Map<string,any[]>{
        return this.dropdownListNNC;
    }

    setDropDownPazienti(data: any) {
        this.dropdownListPazienti = data;
    }
    getDropDownPazienti(): Map<string,any[]>{
        return this.dropdownListPazienti;
    }

    setSearch(data: TestataOrdinePaged | undefined){
        this.ordini = data;
    }
    getSearch(): TestataOrdinePaged | undefined{
        return this.ordini;
    }

    setSearchNNC(data: TestataOrdinePaged | undefined){
        this.ordiniNNC = data;
    }
    getSearchNNC(): TestataOrdinePaged | undefined{
        return this.ordiniNNC;
    }

    setSearchPazienti(data: TestataOrdinePaged | undefined){
        this.ordiniPazienti = data;
    }
    getSearchPazienti(): TestataOrdinePaged | undefined{
        return this.ordiniPazienti;
    }

    setSearchType(data: string | undefined){
        this.searchType = data;
    }
    getSearchType(): string | undefined{
        return this.searchType;
    }

    setSearchParams(oggetto: Ordine | undefined){
        this.oggetto = oggetto;
    }
    getSearchParams(): Ordine | undefined{
        return this.oggetto;
    }

    setSearchParamsNNC(oggetto: Ordine | undefined){
        this.oggettoNNC = oggetto;
    }
    getSearchParamsNNC(): Ordine | undefined{
        return this.oggettoNNC;
    }

    setSearchParamsPazienti(oggetto: Ordine | undefined){
        this.oggettoPazienti = oggetto;
    }
    getSearchParamsPazienti(): Ordine | undefined{
        return this.oggettoPazienti;
    }

    setSearchStringParams(data: string, page: number){
        this.searchStringParams = [data, page];
    }
    getSearchStringParams(): [string,number] | ["",0]{
        return this.searchStringParams;
    }

    setSearchStringParamsNNC(data: string,page: number){
        this.searchStringParamsNNC = [data,page];
    }
    getSearchStringParamsNNC(): [string,number] | ["",0]{
        return this.searchStringParamsNNC;
    }

    setSearchStringParamsNC(data: string,page: number){
        this.searchStringParamsNC = [data,page];
    }
    getSearchStringParamsNC(): [string,number] | ["",0]{
        return this.searchStringParamsNC;
    }

    setSearchStringParamsPazienti(data: string,page: number){
        this.searchStringParamsPazienti = [data,page];
    }
    getSearchStringParamsPazienti(): [string,number] | ["",0]{
        return this.searchStringParamsPazienti;
    }

    setOrder(ordine: TestataOrdine) {
        this.ordine = ordine;
    }
    getOrder(): TestataOrdine {
        return this.ordine;
    }

    setModeNC(nc: boolean){
        this.modeNC = nc;
    }
    getModeNC(){
        return this.modeNC;
    }

    setOrderNNC(ordine: TestataOrdine) {
        this.ordineNNC = ordine;
    }
    getOrderNNC(): TestataOrdine {
        return this.ordineNNC;
    }

    setNCSpecifica(ordine: DettaglioOrdine) {
        this.ncspecifica = ordine;
    }
    getNCSpecifica(): DettaglioOrdine {
        return this.ncspecifica;
    }

    setSearchOrder(ordine: TestataOrdine) {
        this.searchOrdine = ordine;
    }
    getSearchOrder(): TestataOrdine {
        return this.searchOrdine;
    }

    setPaziente(paziente: DettaglioOrdine){
        this.paziente = paziente;
    }
    getPaziente(): DettaglioOrdine {
        return this.paziente;
    }

    setNCgenerica(nc: NCgenerica){
        this.ncGenerica = nc;
    }
    getNCgenerica(): NCgenerica {
        return this.ncGenerica;
    }


    getOrdineById(id: any): Observable<TestataOrdine> {
        const url = 'testataordine/dto/id=' + id.toString();
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => new TestataOrdine(res))
            );
    }

    getNCById(id: any): Observable<NCgenerica> {
        const url = 'nonconformita/dto/id=' + id.toString();
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => new NCgenerica(res))
            );
    }

    getDettaglioOrdineById(id: any): Observable<DettaglioOrdine> {
        const url = 'dettaglioordine/dto/id=' + id.toString();
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => new DettaglioOrdine(res))
            );
    }


    getTipoStruttura(): Observable<TipoStruttura[]> {
        const url = 'vistrutturaflat/risto/tipostruttura';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: TipoStruttura) => new TipoStruttura(out)))
            );
    }

    getPiatti(piatto: string, idTestata: any, idDettaglio:any): Observable<Piatti[]> {
        const url = 'dettaglioordine/piattipossibili?descrizione='+piatto+'&idtestata='+idTestata.toString()+'&iddettaglioordine='+idDettaglio.toString();
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: Piatti) => new Piatti(out)))
            );
    }



    getNextVal(): Observable<TestataOrdine> {
        const url = 'testataordine/nextval';
        return this.apiRestService.getWithHeader(url)
            .pipe();
    }

    getStruttura(): Observable<Struttura[]> {
        const url = 'strutture/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: Struttura) => new Struttura(out)))
            );
    }

    getCausaliGeneriche(): Observable<CausaliNC[]> {
        const url = 'causalinonconformitaristo/allgeneriche';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: CausaliNC) => new CausaliNC(out)))
            );
    }

    getDieteGeneriche(idTestata: string): Observable<Dieta[]> {
        const url = 'nonconformita/dieteinserite/id=' + idTestata;
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: Dieta) => new Dieta(out)))
            );
    }

    getTipiMenuGeneriche(idTestata: string, idDieta: string): Observable<TipoMenu[]> {
        const url = 'nonconformita/tipimenuinseriti?idtestata=' + idTestata + '&iddieta=' + idDieta;
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: TipoMenu) => new TipoMenu(out)))
            );
    }

    getPiattiGeneriche(idTestata: string, idTipoMenu: string, descPiatto: string): Observable<Piatto[]> {
        const url = 'nonconformita/piattiinseriti?idtestata=' + idTestata + '&idtipomenu=' + idTipoMenu + '&descpiatto=' + descPiatto;
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: Piatto) => new Piatto(out)))
            );
    }

    getListaPazienti(id: any): Observable<Pazienti[]> {
        const url = 'dettaglioordine/id='+ id.toString() + "/pazienti";
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: Pazienti) => new Pazienti(out)))
            );
    }

    getPazientiPresentiByUS(id: any): Observable<Pazienti[]> {
        const url = 'pazienti/dto/presenti/idus='+ id.toString();
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: Pazienti) => new Pazienti(out)))
            );
    }

    getDietePaziente(id: any, dieta: any): Observable<TipoMenu[]> {
        const url = 'dettaglioordine/tipimenu?idtestata='+ id.toString() + '&iddieta=' + dieta.toString();
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((out: TipoMenu) => new TipoMenu(out)))
            );
    }

    getStrutturaByIdTipo(tipoStruttura: any): Observable<Struttura[]> {
        const url = 'vistrutturaflat/risto/struttura?tipostrutturaid='+ tipoStruttura.toString();
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: Struttura) => new Struttura(out)))
            );
    }

    getUnitaServizio(): Observable<UnitaServizio[]> {
        const url = 'unitaservizio/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: UnitaServizio) => new UnitaServizio(out)))
            );
    }

    getUnitaServizioByIdStruttura(idStruttura: any): Observable<UnitaServizio[]> {
        const url = 'utentiunitaservizio/dto/risto/idstruttura='+idStruttura.toString();
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: UnitaServizio) => new UnitaServizio(out)))
            );
    }

    getTipoPasto(): Observable<TipoPasto[]> {
        const url = 'tipopasto/dto/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: TipoPasto) => new TipoPasto(out)))
            );
    }

    getCausali(): Observable<CausaliNC[]> {
        const url = 'causalinonconformitaristo/allgiornaliere';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: CausaliNC) => new CausaliNC(out)))
            );
    }

    getCausaliSpecifiche(): Observable<CausaliNC[]> {
        const url = 'causalinonconformitaristo/allspecifiche';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: CausaliNC) => new CausaliNC(out)))
            );
    }

    getTipoOrdineByPastoAndDate(idTipoPasto: number, dataPasto: string): Observable<TipoOrdine> {
        const url = 'tipoordine/setvalue?idtipopasto='+idTipoPasto.toString()+'&datapasto='+dataPasto;
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => new TipoOrdine(res))
            );
    }

    getTipoOrdine(): Observable<any[]> {
        const url = 'tipoordine/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((out: any) => new TipoOrdine(out)))
            );
    }

    getStatoOrdine(): StatoOrdine[] {
        const statoOrdine: StatoOrdine[] = [];
        statoOrdine.push(new StatoOrdine({stato: 'Provvisorio'}));
        statoOrdine.push(new StatoOrdine({stato: 'Validato'}));
        statoOrdine.push(new StatoOrdine({stato: 'Inviato'}));
        statoOrdine.push(new StatoOrdine({stato: 'Consegnato'}));
        statoOrdine.push(new StatoOrdine({stato: 'Annullato'}));
        statoOrdine.push(new StatoOrdine({stato: 'Rettificato'}));
        statoOrdine.push(new StatoOrdine({stato: 'Manuale'}));
        return statoOrdine;
    }

    getStatoValidazione(): StatoOrdine[]{
        const statoOrdine: StatoOrdine[] = [];
        statoOrdine.push(new StatoOrdine({stato: 'DA VALIDARE'}));
        statoOrdine.push(new StatoOrdine({stato: 'VALIDATA'}));
        return statoOrdine;
    }


    getStatoTicket(): StatoOrdine[] {
        const statoOrdine: StatoOrdine[] = [];
        statoOrdine.push(new StatoOrdine({stato: 'INSERITA', valore: 1}));
        statoOrdine.push(new StatoOrdine({stato: 'PRESA IN CARICO OPERATORE', valore: 13}));
        statoOrdine.push(new StatoOrdine({stato: 'ASSEGNATA AL GESTORE', valore: 18}));
        statoOrdine.push(new StatoOrdine({stato: 'PRESA IN CARICO GESTORE', valore: 20}));
        statoOrdine.push(new StatoOrdine({stato: 'CHIUSA', valore: 30}));
        statoOrdine.push(new StatoOrdine({stato: 'RESPINTA', valore: 35}));
        statoOrdine.push(new StatoOrdine({stato: 'APERTA', valore: 10}));
        statoOrdine.push(new StatoOrdine({stato: 'RESPINTA IN ATTESA DI CONFERMA', valore: 15}));
        return statoOrdine;
    }


    setRichiestaId(id: string, idTicket: string): Observable<any> {
        const url = `ordinipulizieordinarie/assegnarichiesta/id=`+ id + '/idTicket=' + idTicket;
        return this.apiRestService.patchSenzaBody(url);
    }

    validaOrdine(id: string): Observable<any> {
        const url = `testataordine/valida/id=`+ id;
        return this.apiRestService.patchSenzaBody(url);
    }

    consegnaOrdine(id: string): Observable<any> {
        const url = `testataordine/consegnato/id=`+ id;
        return this.apiRestService.patchSenzaBody(url);
    }

    creaTicket(idordine: string): Observable<any> {
        const url = 'ticket/savebyall/idordine='+ idordine +'/modulo=ristorazione/tipo=NC';
        return this.apiRestService.patchSenzaBodyAlt(url);
    }

    updateTicketNC(idNC:string, idTicket:string): Observable<any> {
        const url = 'nonconformita/assegnarichiesta/id='+ idNC +'/idTicket=' + idTicket;
        return this.apiRestService.patchSenzaBody(url);
    }

    accettaChiusura(id:string): Observable<any> {
        const url = 'nonconformita/accettachiusura/id='+ id;
        return this.apiRestService.patchSenzaBody(url);
    }

    contestaChiusura(id:string): Observable<any> {
        const url = 'ticket/savebyall/idordine='+ id + '/modulo=ristorazione/tipo=NC';
        return this.apiRestService.patchSenzaBodyAlt(url);
    }

    eliminaNC(id: string): Observable<any> {
        const url = 'nonconformita/elimina/id='+ id;
        return this.apiRestService.patchSenzaBody(url);
    }


    rettificaOrdine(id: string): Observable<any> {
        const url = `testataordine/rettifica/id=`+ id;
        return this.apiRestService.patchSenzaBody(url);
    }

    eliminaOrdine(id: string): Observable<any> {
        const url = `testataordine/annulla/id=`+ id;
        return this.apiRestService.patchSenzaBody(url);
    }

    eliminaPasto(id: string): Observable<any> {
        const url = `dettaglioordine/eliminapasto/iddettordine=`+ id;
        return this.apiRestService.patchSenzaBody(url);
    }


    getPuliziePaged(id: number): Observable<PuliziePaged[]> {
        const url = 'ordinipulizieordinarie/dto/filtratapaged/id='+id;
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((data: any) => new PuliziePaged(data)))
            );
    }

    getTestateOrdini(page: number, size: number, params: string): Observable<TestataOrdinePaged> {
        const url = 'testataordine/dto/filtratapaged?page='+page+'&size='+size + params;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithHeader(url,headers)
            .pipe(
                map(res => new TestataOrdinePaged(res))
            );
    }

    getNonConformita(page: number, size: number, params: string): Observable<NCgenericaPaged> {
        const url = 'nonconformita/dto/filtratapaged?page='+page+'&size='+size + params;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithHeader(url,headers)
            .pipe(
                map(res => new NCgenericaPaged(res))
            );
    }

    getTestateOrdiniConsegnati(page: number, size: number, params: string): Observable<TestataOrdinePaged> {
        const url = 'testataordine/nonconformita/filtratapaged?page='+page+'&size='+size + params;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithHeader(url,headers)
            .pipe(
                map(res => new TestataOrdinePaged(res))
            );
    }

    getTestateOrdiniPazienti(page: number, size: number, params: string): Observable<TestataOrdinePaged> {
        const url = 'testataordine/ricercapaziente/filtratapaged?page='+page+'&size='+size + params;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithHeader(url,headers)
            .pipe(
                map(res => new TestataOrdinePaged(res))
            );
    }

    createTestataOrdine(ordine: TestataOrdine): Observable<TestataOrdine> {
        const url = `testataordine/salva`;
        return this.apiRestService.postWithBody(url, ordine.getOggCreate());
    }

    createDettaglioOrdine(ordine: DettaglioOrdine): Observable<TestataOrdine> {
        const url = `dettaglioordine/salva`;
        return this.apiRestService.postWithBody(url, ordine);
    }

    createPiatti(ordine: DettaglioOrdine): Observable<TestataOrdine> {
        const url = `dettagliopiatti/salva`;
        return this.apiRestService.postWithBody(url, ordine);
    }

    createNCGenerica(nc: NCgenerica): Observable<NCgenerica> {
        const url = `nonconformita/inseriscigenerica`;
        return this.apiRestService.postWithBody(url, nc);
    }

    createNCSpecifica(nc: NCgenerica): Observable<NCgenerica> {
        const url = `nonconformita/inseriscispecifica`;
        return this.apiRestService.postWithBody(url, nc);
    }
}
