export class FormResponse {
  type: string;
  data: any;

  constructor(type: string, data: any) {
    this.type = type;
    this.data = data;
  }
  getOggUpdate() {
    return this.getOggCreate();
  }

  getOggCreate(): FormResponseCreate {
    return {
      type: this.type,
      data: this.data
    };
  }
}

export interface FormResponseCreate {
  type: string;
  data: any;
}

export interface FormResponseUpdate extends FormResponseCreate {

}
