<form class="form-base" *ngIf="formGroup && !confPopup" [formGroup]="formGroup" (ngSubmit)="actionSave()">

  <div class="formContainer" *ngFor="let formLine of arrayFormInfo">
    <div class="formFieldGroup">

      <label [for]="formLine.prop" class="form-label"> {{formLine.text}}: </label>

      <div class="form-input">
        <app-form-inputs [formType]="formLine"
                         [formControlName]="formLine.prop"
                         [objValue]="objValue"
                         [dropdownList]="selectsGroup!.get(formLine!.list!)"
                         [dropdownList2]="selectsGroup!.get(formLine!.list2!)">
        </app-form-inputs>
      </div>

      <div class="form-error"> <span *ngIf="formGroup!.controls[formLine.prop].invalid"> * </span> </div>

    </div>

  </div>

  <div class="formContainer">
    <div class="formFieldGroup">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs" class="legenda-form-error">
      <span *ngIf="formGroup!.controls[msg.prop].invalid && formGroup!.controls[msg.prop].touched" class="error-validator">{{msg.msg}}</span>
    </div>
  </div>

  <div class="footerForm" *ngIf="enableFooter">
    <app-form-field-footer [disabled]="formGroup!.invalid"
                           [showCancel]="showCancel"
                           [saveStyle]="saveStyle"
                           [textCancel]="textCancel"
                           [textSave]="textSave"
                           [cancelStyle]="cancelStyle"
                           [cancelAsReset]="cancelAsReset"
                           [showSave]="showSave"
                           [blockSave]="blockSave"
                           (saveForm)="actionSave()"
                           (reset)="actionReset()"
                           (cancel)="actionCancel()">
    </app-form-field-footer>
  </div>

</form>
<form class="form-base" style="display: flow-root;"*ngIf="formGroup && confPopup" [formGroup]="formGroup" (ngSubmit)="actionSave()">
  <div class="border">
    <br>
    <table style="margin:auto;">
      <div class="" *ngFor="let formLine of arrayFormInfo">
        <tr>
          <td>
          <label [for]="formLine.prop" style="font-weight: bold;"> {{formLine.text}}:&nbsp;&nbsp;</label>
          </td><td>
            <div class="" style="max-width: fit-content;">
              <app-form-inputs [formType]="formLine"
                               [formControlName]="formLine.prop"
                               [objValue]="objValue"
                               [confPopup]="confPopup"
                               [dropdownList]="selectsGroup!.get(formLine!.list!)"
                               [dropdownList2]="selectsGroup!.get(formLine!.list2!)">
              </app-form-inputs>
            </div>
        </td>
        </tr>
        <br>
          <div class="form-error"> <span *ngIf="formGroup!.controls[formLine.prop].invalid"> * </span> </div>
      </div>
    </table>
    <br>
  </div>
  <div class="formContainer">
    <div class="formFieldGroup">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs" class="legenda-form-error">
      <span *ngIf="formGroup!.controls[msg.prop].invalid && formGroup!.controls[msg.prop].touched" class="error-validator">{{msg.msg}}</span>
    </div>
  </div>

  <div class="footerForm" *ngIf="enableFooter">
    <app-form-field-footer [disabled]="formGroup!.invalid"
                           [showCancel]="showCancel"
                           [saveStyle]="saveStyle"
                           [textCancel]="textCancel"
                           [textSave]="textSave"
                           [confPopup]="confPopup"
                           [cancelStyle]="cancelStyle"
                           [cancelAsReset]="cancelAsReset"
                           [showSave]="showSave"
                           [blockSave]="blockSave"
                           (saveForm)="actionSave()"
                           (reset)="actionReset()"
                           (cancel)="actionCancel()">
    </app-form-field-footer>
  </div>

</form>
