import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BleDropdownComponent, FilterInput} from './ble-dropdown/ble-dropdown.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BleDatepickerComponent} from './ble-datepicker/ble-datepicker.component';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BleInputComponent} from './ble-input/ble-input.component';
import {MatInputModule} from '@angular/material/input';
import {BleInputNumberComponent} from './ble-input-number/ble-input-number.component';
import {BleInputTextareaComponent} from './ble-input-textarea/ble-input-textarea.component';
import {BlePasswordComponent} from './ble-password/ble-password.component';
import {DatepickerHeaderComponent} from './ble-datepicker/datepicker-header/datepicker-header.component';
import {BleCheckboxComponent} from './ble-checkbox/ble-checkbox.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BleSlideComponent} from './ble-slide/ble-slide.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {InputTreeComponent} from "./input-tree/input-tree.component";
import {MatTreeModule} from "@angular/material/tree";
import {BleInputTimeComponent} from "./ble-input-time/ble-input-time.component";
import {MatTimepickerModule} from "mat-timepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DatetimepickerHeaderComponent} from "./ble-datetimepicker/datepicker-header/datetimepicker-header.component";
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {BleDatetimepickerComponent} from "./ble-datetimepicker/ble-datetimepicker.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BleIconComponent} from "./ble-icon/ble-icon.component";
import {BleRadioComponent} from "./ble-radio/ble-radio.component";
import {MatRadioModule} from "@angular/material/radio";

// const lang = configuration.language == 'it' ? 'it-IT' : 'en-EN';

@NgModule({
  declarations: [
    BleDropdownComponent,
    BleDatepickerComponent,
    BleInputComponent,
    BleIconComponent,
    BleInputNumberComponent,
    BleInputTextareaComponent,
    BlePasswordComponent,
    DatepickerHeaderComponent,
    DatetimepickerHeaderComponent,
    FilterInput,
    BleCheckboxComponent,
    BleSlideComponent,
    InputTreeComponent,
    BleInputTimeComponent,
    BleDatetimepickerComponent,
    BleRadioComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTreeModule,
    MatTooltipModule,
    MatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FontAwesomeModule
  ],
  exports: [
    BleDropdownComponent,
    BleDatepickerComponent,
    BleDatetimepickerComponent,
    BleInputComponent,
    BleInputNumberComponent,
    BleInputTextareaComponent,
    BlePasswordComponent,
    BleCheckboxComponent,
    BleSlideComponent,
    InputTreeComponent,
    BleInputTimeComponent,
    BleIconComponent,
    BleRadioComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }]
})
export class BleInputModule { }
