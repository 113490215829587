<!-- Input Text -->
<input matInput
       class="inputStandard inputForm"
       type="text"
       maxlength={{max}}
       [ngClass]="{ 'input-error': invalid }"
       [(ngModel)]="valore"
       [disabled]="block ? block : false"
       [hidden]="hidden ? hidden: false"
       [placeholder]="testo ? testo : ''"
       (keyup)="inputChange()">
