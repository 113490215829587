import {Component, HostListener} from '@angular/core';
import {Subject} from "rxjs";
import {SdgLibService} from "sdg-lib";
import {RistorazioneAppService} from "./services/ristorazione-app.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  title = 'ristorazione';

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  constructor(public ristorazioneService: RistorazioneAppService) {
    this.ristorazioneService.setLastActivity(new Date());
  }

  @HostListener('touchstart')
  @HostListener('mousemove') lastActivity() {
    console.log("Actvity");
    this.ristorazioneService.setLastActivity(new Date());
  }

}
