<ng-container [ngSwitch]="rowProp">
    <div *ngIf="onlyView && !text0 && !text1">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{rowData[rowProp]}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && !text0 && !text1">
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data cursor-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <div *ngIf="!colTooltip && !html">
                {{rowData[rowProp]}}
            </div>
            <div *ngIf="!colTooltip && html">
                <div [innerHTML]="rowData[rowProp]"></div>
            </div>
        </div>
    </div>
    <div *ngIf="onlyView && text0 && text1">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && text0 && text1">
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data cursor-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                test4
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngSwitchCase="'valida'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                        data: rowData,
                                        type: state.VALIDA,
                                        icon: 'fas fa-clipboard-check',
                                        color:'primary',
                                        disabled: !rowData?.enableValida
                                        }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'stampa'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                                    data: rowData,
                                                    type: state.STAMPA,
                                                    icon: 'fas fa-print',
                                                    color:'primary',
                                                    disabled: !rowData?.enablePdf
                                                    }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'images'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                                    data: rowData,
                                                    type: state.FOTO,
                                                    icon: 'far fa-file-image',
                                                    color:'primary',
                                                    disabled: false
                                                    }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'cancellaScheda'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
    data: rowData,
    type: state.DELETE,
    icon: 'fas fa-trash-alt',
    color:'warn' ,
    disabled: rowData?.stato?.descStato != statoIntervento.DA_ASSEGNARE
    }"></ng-container>
    </div>

    <div *ngSwitchCase="'cancella'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
    data: rowData,
    type: state.DELETE,
    icon: 'fas fa-trash-alt',
    color:'warn'
    }"></ng-container>
    </div>

    <div *ngSwitchCase="'authCancel'" class="table-cancel">
        <div *ngIf="rowData.delete" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                data: rowData,
                type: state.DELETE,
                icon: 'fas fa-trash-alt',
                color:'warn'
                }"></ng-container>
        </div>
        <div *ngIf="!rowData.delete" class="table-cancel">
            <span>
                 {{rowData[rowProp]}}
            </span>
        </div>
    </div>

    <div *ngSwitchCase="'modifica'" class="table-modifica">
        <ng-container
                *ngTemplateOutlet="button; context: { data: rowData, type: state.MODIFICA, icon: 'fas fa-edit', color:'primary' ,disabled: !admin}"></ng-container>
    </div>

    <div *ngSwitchCase="'controlli'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.MODIFICA, icon: 'fas fa-edit', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
    </div>

    <div *ngSwitchCase="'addUpDownDel'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CREA, icon: 'fas fa-plus', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.VALIDA, icon: 'fas fa-thumbs-up', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CONTESTA, icon: 'fas fa-thumbs-down', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
    </div>

    <div *ngSwitchCase="'detMarkDel'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CHIUDI, icon: 'fas fa-check', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'delDet'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
        <div *ngSwitchCase="'del'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
    </div>
    <div *ngSwitchCase="'drop01'" class="table-controlli">
        <ng-container *ngTemplateOutlet="dropdown; context: { data: rowData, type: state.VALUE, disabled: !admin}"></ng-container>
    </div>
</ng-container>


<!-- Button -->
<ng-template #button let-data="data" let-type="type" let-icon="icon" let-color="color" let-disabled="disabled">
    <button mat-icon-button
            type="button"
            [color]="color"
            [disabled]="disabled"
            (click)="callbackEmit(data, type)">
        <i [ngClass]="icon"></i>
    </button>

</ng-template>

<!-- Small Button -->
<ng-template #smallbutton let-data="data" let-type="type" let-icon="icon" let-color="color" let-disabled="disabled">
    <button mat-icon-button class="mat-icon-small-button"
            type="button"
            [color]="color"
            [disabled]="disabled"
            (click)="callbackEmit(data, type)">
        <i [ngClass]="icon"></i>
    </button>
</ng-template>

<!-- Dropdown -->
<ng-template #dropdown let-data="data" let-type="type" let-disabled="disabled">
        <mat-select [(ngModel)]=selected
                    (selectionChange)="callbackDropEmit(data, type, selected)">
            <mat-option [value]="0">{{text0}}</mat-option>
            <mat-option [value]="1">{{text1}}</mat-option>
        </mat-select>
</ng-template>
