import {MyDate} from "./MyDate";

export class Tariffe {

    tipoTariffa?: string;
    id?: number;
    costoOsp?: number;
    costoInso?: number;
    dataInizioVal?: MyDate | undefined;
    dataFineVal?: MyDate | undefined;
    userId?: number;
    dataModifica?: MyDate | undefined;

    constructor(data: any) {
        this.tipoTariffa = data.tipoTariffa;
        this.id = data.id;
        this.costoOsp = data.costoOsp;
        this.costoInso = data.costoInso;
        this.dataInizioVal = data.dataInizioVal? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;
        this.userId = data.userId;
        this.dataModifica = data.dataModifica ? new MyDate(data.dataModifica) : undefined;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.tipoTariffa ? this.tipoTariffa : '';
    }

    get value(): Tariffe {
        return this;
    }

}

export class CausaliSaveNC {

    id: number;

    constructor(data: any) {
        this.id = data.id;
    }
}
