<div class="view-container mat-elevation-z4">

  <div class="loading" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

    <app-table-header *ngIf="showHeader"
                      [tableType]="tableType"
                      [filterHeader]="filterHeader"
                      [filterHeaderList]="filterHeaderList"
                      [filterDataList]="filterDataList"
                      [centerTitle]="centerTitle"
                      [buttonAdd]="buttonAdd"
                      [disableAdd]="disableAdd"
                      [buttonAddName]="buttonAddName"
                      [buttonFunc]="buttonFunc"
                      [buttonFuncName]="buttonFuncName"
                      [cleanName]="cleanName"
                      [buttonAggiorna]="buttonAggiorna"
                      (setFilter)="setFilter($event)"
                      (callBack)="callBack.emit($event)">
    </app-table-header>


     <div [ngClass]="{ 'loading': loading }" *ngIf="_tableData">
       <app-table-base [tableData]="_tableData"
                       [rows]="rows"
                       [pageIndex]="pageIndex"
                       [noRowPattern]="noRowPattern"
                       [colorSelected]="colorSelected"
                       [rowResize]="rowResize"
                       [emptyMessage]="emptyMessage"
                       [totalPageslazy]="totalPageslazy"
                       [tableDataHeader]="tableDataHeader"
                       [showFooter]="showFooter"
                       (callBack)="callBack.emit($event)"
                       (callBackPaginator)="callBackPaginator.emit($event)">
       </app-table-base>
     </div>


</div>
