<!-- Input textarea -->
<textarea class="inputStandard"
          matInput
          type="textarea"
          [maxLength]="498"
          [ngClass]="{ 'input-error': invalid }"
          [(ngModel)]="valore"
          [hidden]="hidden ? hidden: false"
          [rows]="rows"
          [cols]="cols"
          [disabled]="block ? block : false"
          (keyup)="inputChange()"
          [placeholder]="testo ? testo : ''">
</textarea>
